const initialState = {
  start: 0,
  page: 0,
  rePopulateData: false,
};

const fundsPaginationReducer = (state = initialState, action) => {
  if (action.type === "setFundPageStart") {
    state = {
      ...state,
      start: action.payload,
    };
    return state;
  } else if (action.type === "setFundPageCount") {
    state = {
      ...state,
      page: action.payload,
    };
    return state;
  } else if (action.type === "resetFundPagination") {
    state = {
      start: 0,
      page: 0,
      rePopulateData: false,
    };
    return state;
  } else if (action.type === "repopulate") {
    state = {
      ...state,
      rePopulateData: !state.rePopulateData,
    };
    return state;
  } else return state;
};

export default fundsPaginationReducer;
