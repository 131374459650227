import { Avatar, Box, ListItemText, makeStyles, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { stocksWithStats } from '../api/Dashboard';
import { currencyFormatter } from './funtions/currencyFormatter';
import { getToken } from './funtions/getToken';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});
const StocksUnderManagement = () => {
    const classes = useStyles();
    const token = getToken()
    const [data, setData] = useState([]);
    const [start, setStart] = useState(0);
    const [stock, setStock] = useState(null);
    const [page, setPage] = useState(0);
    // const navigate = useNavigate();
    const setStockData = (event) => {
        setStock(event.target.value)
    }
    const getStocks = async () => {
        let payload = {
            start,
            stock
        };
        const result = await stocksWithStats(payload, token);
        setData(result.data.data.transactions);
        setPage(result.data.data.total_pages);
    }

    useEffect(() => {
        getStocks()
        // eslint-disable-next-line
    }, [start, stock])
    return (
        <>
            {/* <Box className='mx-1' display={"flex"} justifyContent="space-between" paddingY={3} ><Box className='fw-700 fs-18 text-blue-dark'>Strategies Under Management </Box><Button className={"bg-color-blue-light text-blue"}>View All Strategies</Button></Box> */}
            <Box className='mx-1' display={"flex"} justifyContent="space-between" paddingY={2} ><Box className='fw-700 fs-18 text-blue-dark'>Stocks Under Management </Box> <Box marginBottom={2} width={"50%"}><OutlinedInput fullWidth className='mx-1 h-32 ' color='secondary' placeholder='Search Stocks' onChange={setStockData} /></Box>
            </Box>
            <Box width="100%" className='overflow-scroll-visible' height="380px">
                {data?.length < 1 ? <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="300px">
                    <Box className="fs-14 fw-500 text-black">
                        No Data Available
                    </Box>
                </Box> :
                    <TableContainer  >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell className='fs-10 fw-600 text-grey' >STOCKS</TableCell>
                                    <TableCell className='fs-10 fw-600 text-grey' align="center">TOTAL PURCHASE VALUE</TableCell>
                                    <TableCell className='fs-10 fw-600 text-grey' align="center">TOTAL SALE VOLUME</TableCell>
                                    <TableCell className='fs-10 fw-600 text-grey' align="center">AXEHEDGE EARNINGS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.map((value, index) => (
                                    <TableRow key={index} className="mouse-click animate" >
                                        <TableCell component="th" scope="row">
                                            <Box display={"inline-flex"} alignItems="center" >
                                                <ListItemText primaryTypographyProps={{ className: "fw-600 fs-14 text-blue-dark" }} secondaryTypographyProps={{ className: "fw-500 fs-12" }} primary={value._id} secondary={<Box display={'inline-flex'} alignItems="center"  ><Avatar className="avatar-h-1" src={value?.stock_details.image} /><span className='mx-5px fs-10 fw-400'>{value?.stock_details.name?.length < 16 ? `${(value.stock_details.name).slice(0, 15)}...` : value.stock_details.name}</span></Box>} />
                                            </Box >
                                        </TableCell>

                                        <TableCell align="center"><ListItemText primaryTypographyProps={{ className: "fw-600 fs-14 text-blue-dark" }} secondaryTypographyProps={{ className: "fw-500 fs-10 text-success" }} primary={`$${currencyFormatter(value.total_purchase)}`} /></TableCell>
                                        <TableCell align="center"><ListItemText primaryTypographyProps={{ className: "fw-600 fs-14 text-blue-dark" }} secondaryTypographyProps={{ className: "fw-500 fs-10 text-success" }} primary={`$${currencyFormatter(value.total_sell)}`} /></TableCell>
                                        <TableCell align="center"><ListItemText primaryTypographyProps={{ className: "fw-600 fs-14 text-blue-dark" }} secondaryTypographyProps={{ className: "fw-500 fs-10 text-success" }} primary={`$${currencyFormatter(value.total_earnings)}`} /></TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>

                        </Table>
                    </TableContainer>
                }
            </Box>

            {
                page > 1 || data?.length !== 0 ? <Pagination
                    className="text-blue my-5px flex item-center flex-end"
                    color="secondary"
                    showFirstButton={true}
                    showLastButton={true}
                    hideNextButton={false}
                    hidePrevButton={false}
                    count={page}
                    onChange={(e, index) => {
                        let newindex = index - 1;
                        setStart(newindex);
                    }}

                /> : null
            }
        </>
    )
}

export default StocksUnderManagement