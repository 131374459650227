import {
  InputAdornment,
  Select,
  InputLabel,
  TextField,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CustomSnackBar from "./CustomSnackBar";
import { useState, useEffect } from "react";
import { editUsernameEmail } from "../api/user";

const EditProfileModal = ({ open, handleClose, setError, id, token, email, username }) => {
  const [formData, setFormData] = useState({
    // username: userData.username,
    // email: userData.email,
    // phoneNumber: userData.phoneNumber,
    // dateOfBirth: userData.dateOfBirth,
    username: username,
    email: email,
    // phoneNumber: "",
    // dateOfBirth: {
    //   day: "",
    //   month: "",
    //   year: "",
    // },
    // country: "MY",
  });

  const textFieldStyle = {
    width: "100%",
    margin: "8px 0", // Adjust as needed
    borderRadius: "8px", // Add rounded corners
    border: "2px solid #ced4da", // Add border
  };

  // const countries = [
  //   { code: "MY", prefix: "+60" },
  //   { code: "CA", prefix: "+10" },
  //   // Add more countries as needed
  // ];

  // const days = Array.from({ length: 31 }, (_, index) => (index + 1).toString());
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];
  // const years = Array.from({ length: 100 }, (_, index) =>
  //   (2022 - index).toString()
  // );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleDateChange = (name) => (e) => {
  //   const { value } = e.target;
  //   setFormData({
  //     ...formData,
  //     dateOfBirth: {
  //       ...formData.dateOfBirth,
  //       [name]: value,
  //     },
  //   });
  // };

  const handleFormData = () => {
    formData.username = username
    formData.email = email
  }

  useEffect(() => {
    handleFormData()
}, [username,email])

  const handleSubmit = async () => {
    if(formData.username === ""){
      formData.username = username
    }
    if(formData.email === ""){
      formData.email = email
    }
    const result = await editUsernameEmail(id, formData.username, formData.email, token );
      if(result.data.is_success === false){
        setError(result.data.message)
      }
      handleClose();
      };

  const [toastObj, setToastObj] = useState({
    open: false,
    snackbarMsg: "",
    type: "success",
    onClose: () => {
      setToastObj((prev) => {
        return {
          ...prev,
          open: false,
        };
      });
    },
  });

  return (
    <>
      <CustomSnackBar {...toastObj} />
      <Dialog
        PaperProps={{ className: "border-radius-15 " }}
        fullWidth={false}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          <Box
            width="100%"
            display="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            className="fs-28 fw-700 text-blue-dark"
          >
            <span>Edit Profile</span>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className="overflow-scroll-hidden">
          <Box
            display="flex"
            flexDirection="column"
            minWidth="380px"
            justifyContent="space-between"
            width="100%"
            marginTop={1}
          >
            <InputLabel shrink={false}>Username</InputLabel>
            <TextField
              label=""
              variant="outlined"
              fullWidth
              margin="normal"
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleChange}
              style={textFieldStyle} // Apply the style
            />
          </Box>
          <Box
            display="flex"
            minWidth="380px"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            marginTop={2}
          >
            <InputLabel shrink={false}>Email</InputLabel>
            <TextField
              label=""
              variant="outlined"
              fullWidth
              margin="normal"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              style={textFieldStyle} // Apply the style
            />
          </Box>
          {/* <Box marginTop={2}>
            <InputLabel shrink={false}>Phone Number</InputLabel>
          </Box> */}
          {/* <Box
            display="flex"
            minWidth="380px"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box flex="1.5" paddingRight={2}>
              <Select
                native
                variant="outlined"
                margin="normal"
                value={formData.country}
                onChange={handleChange}
                name="country"
                style={textFieldStyle}
                height="40px"
                padding={2}
              >
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.code}({country.prefix})
                  </option>
                ))}
              </Select>
            </Box>
            <Box flex="3">
              <TextField
                label=""
                variant="outlined"
                fullWidth
                margin="normal"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                style={textFieldStyle} // Apply the style
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {
                        countries.find((c) => c.code === formData.country)
                          ?.prefix
                      }
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box> */}
          {/* <Box marginTop={2}>
            <InputLabel shrink={false}>Date of Birth</InputLabel>
          </Box>          
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box flex="1" paddingRight={1}>
              <Select
                native
                margin="normal"
                variant="outlined"
                value={formData.dateOfBirth.day}
                onChange={handleDateChange("day")}
                name="day"
                style={textFieldStyle}
              >
                <option value="">Day</option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </Select>
            </Box>
            <Box flex="2">
              <Select
                native
                margin="normal"
                variant="outlined"
                value={formData.dateOfBirth.month}
                onChange={handleDateChange("month")}
                name="month"
                style={textFieldStyle}
              >
                <option value="">Month</option>
                {months.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </Select>
            </Box>
            <Box flex="1" paddingLeft={1}>
              <Select
                native
                margin="normal"
                variant="outlined"
                value={formData.dateOfBirth.year}
                onChange={handleDateChange("year")}
                name="year"
                style={textFieldStyle}
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </Box>
          </Box> */}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Box
            className="my-2"
            width={"80%"}
            alignItems="center"
            justifyContent={"center"}
          >
            <Button
              className=" h-40"
              fullWidth
              color="secondary"
              variant="contained"
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProfileModal;
