// import React, { useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from '../assets/images/settingsSidebarIcon.svg';
import SettingsIconActive from '../assets/images/settingsSidebarIconActive.svg';

import homeIcon from "../assets/images/homeSidebarIcon.svg"
import homeIconActive from "../assets/images/homeSidebarIconActive.svg"
import userIcon from "../assets/images/usersSidebarIcon.svg"
import userIconActive from "../assets/images/usersSidebarIconActive.svg"
// import TradesIcon from "../assets/images/tradesSidebarIcon.svg"
// import TradesIconActive from "../assets/images/tradesSidebarIconActive.svg"
import { useNavigate } from "react-router-dom";
// import RequestIcon from "../assets/images/requestsSidebarIcon.svg"
// import RequestIconActive from "../assets/images/requestsSidebarIconActive.svg"
import StrategiesIcon from "../assets/images/StrategiesSidebarIcon.svg"
import StrategiesIconActive from "../assets/images/StrategiesSidebarIconActive.svg"
// import { useSelector } from "react-redux";
const SidebarData = () => {
  // let mode = useSelector((state) => state.modeSwitch.mode)
  const navigate = useNavigate()
  return (<>
    {/* <ListItem button onClick={() => {
      navigate('/dashboard')
    }}>
      <ListItemIcon className="center-items left-12px" >
        {window.location.pathname.includes("dashboard") ? <img src={homeIconActive} alt="homeIconActive" /> : <img src={homeIcon} alt="HomeIcon" />}

      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: window.location.pathname.includes("dashboard") ? "text-white" : "text-grey-new" }}

        primary="Dashboard"
      />
    </ListItem> */}
    {/* <ListItem button onClick={() => {
      navigate('/trades')
    }}>
      <ListItemIcon className="center-items left-12px">
        {window.location.pathname.includes("trades") ? <img src={TradesIconActive} alt="TradesIconActive" /> : <img src={TradesIcon} alt="TradesIcon" />}


      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: window.location.pathname.includes("trades") ? "text-white" : "text-grey-new" }}

        primary="Trades"
      />
    </ListItem> */}
    <ListItem button onClick={() => {
      navigate('/users')
    }}>
      <ListItemIcon className="center-items left-12px">
        {window.location.pathname.includes("users") ? <img src={userIconActive} alt="userIcon" /> : <img src={userIcon} alt="userIcon" />}

      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: window.location.pathname.includes("users") ? "text-white" : "text-grey-new" }}

        primary="Users"
      />
    </ListItem>
    
    {/* {mode === "real" ? <ListItem button onClick={() => {
      navigate('/requests')
    }}>
      <ListItemIcon className="center-items left-12px">
        {window.location.pathname.includes("requests") ? <img src={RequestIConActive} alt="requestIconActive" /> : <img src={RequestICon} alt="requestIcon" />}


      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: window.location.pathname.includes("requests") ? "text-white" : "text-grey-new" }}

        primary="Requests"
      />
    </ListItem> : null} */}

    {/* <ListItem button onClick={() => {
      navigate('/compliance')
    }}>
      <ListItemIcon className="center-items left-12px">
        {window.location.pathname.includes("compliance") ? <img src={RequestIconActive} alt="requestIconActive" /> : <img src={RequestIcon} alt="requestIcon" />}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: window.location.pathname.includes("compliance") ? "text-white" : "text-grey-new" }}

        primary="Compliance"
      />
    </ListItem>  */}

    <ListItem button onClick={() => {
      navigate('/content-management')
    }}>
      <ListItemIcon className="center-items left-12px">
        {window.location.pathname.includes("content-management") ? <img src={StrategiesIconActive} alt="requestIconActive" /> : <img src={StrategiesIcon} alt="requestIcon" />}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: window.location.pathname.includes("content-management") ? "text-white" : "text-grey-new" }}

        primary="Content Management"
      />
    </ListItem> 

    <ListItem button onClick={() => {
      navigate('/settings')
    }}>
      <ListItemIcon className="center-items left-12px">
        {window.location.pathname.includes("settings") ? <img src={SettingsIconActive} alt="SettingsIcon" /> : <img src={SettingsIcon} alt="SettingsIcon" />}
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: window.location.pathname.includes("settings") ? "text-white" : "text-grey-new" }}
        primary="Settings"
      />
    </ListItem>

    {/* <ListItem button>
      <ListItemIcon>
        <PaymentOutlined color={"primary"} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ color: "primary" }}
        primary="Payments"
      />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <SettingsOutlined color={"primary"} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ color: "primary" }}
        primary="Configurations"
      />
    </ListItem> */}
  </>

  )

}

export default SidebarData;
