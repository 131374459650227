import Api from "./index";

export const adminSignIn = async ({ email, password }) => {
  try {
    return await Api.post(`v1/auth/admin/login`, {
      email,
      password,
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const adminCodeVerification = async (code, token) => {
  try {
    return await Api.post(
      `/api/v1/admin/verify-code/signin`,
      {
        code,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const resendCode = async (email, token) => {
  try {
    return await Api.post(
      `/api/v1/auth/admin/new-code`,
      {
        email,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const resetPassword = async (newPassword, oldPassword, token) => {
  try {
    return await Api.post(
      `/v1/auth/admin/change-password`,
      {
        oldPassword,
        newPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};