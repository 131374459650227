const initialState = {
    transactionsListObj: [],
    userTransactionsListObj: [],
    start: 0,
  };
  
const transactions = (state = initialState, action) => {
    if (action.type === "setTransactionListData") {
      state = {
        ...state,
        transactionsListObj: action.payload,
      };
      return state;
    }else if (action.type === "setUserTransactionList") {
      state = {
        ...state,
        userTransactionsListObj: action.payload,
      };
      return state;
  } else return state;
  };
  
  export default transactions;