import {
  Avatar,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie/es6";
import { logout } from "../api/user";
import { getToken } from "./funtions/getToken";
import avatarLogo from '../assets/images/avatar-admin.svg'
import { useSelector, useDispatch } from "react-redux";
import { setAdminName } from "../redux/actions/action";


const TabViewNavMenu = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const anchorRef = useRef(null);
  const cookies = new Cookies()
  const token = getToken();
  const dispatch = useDispatch();
  const adminName = useSelector((state) => state.adminData.name)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const userSignout = async () => {
    const result = await logout(token);
    if (result.status === 200) {
      if (result.data.is_success) {
        await cookies.remove('token', { path: "/" })

      }
    }
  }
  const getAdminData = async () => {
        dispatch(setAdminName('AXEHEDGE'));
  };


  const signOut = async () => {

    userSignout()
    await cookies.remove('token', { path: "/" })
    navigate('/sign-in')
  }

  useEffect(() => {
    if (adminName === null) {
      getAdminData()

    }
  }, [adminName])

  return (
    <>
      <IconButton
        edge="start"
        color="secondary"
        aria-label="open drawer"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MenuIcon />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="left-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >

                  <MenuItem onClick={handleClose}>
                    <Button
                      className={"text-blue min-width-160 border-radius-24 bg-color-blue-light py-5px"}
                      disableFocusRipple
                      disableTouchRipple
                      disableRipple
                      disableElevation
                      startIcon={<Avatar className=" mouse-click" src={avatarLogo} alt="user" />}

                    >
                      {adminName === null ? "Loading..." : adminName}
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={signOut}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default TabViewNavMenu;
