import { Box, Button, CircularProgress, Grid, IconButton, Input } from '@material-ui/core'
import greaterThanIcon from "../assets/images/greaterthan.svg"
import React, { useState } from 'react'
import { AddBox, RemoveCircle } from '@material-ui/icons'
import { getToken } from './funtions/getToken'
import { getAdminSettings, setTradesCommission } from '../api/settings'
import CustomSnackbar from "../components/CustomSnackBar"
import { useEffect } from 'react'

const Commission = () => {
    const [commissionArray, setCommissionArray] = useState([
        {
            number: null,
            min: null,
            maximum: null
        }
    ]);
    const [isLoading, setIsLoading] = useState(true)
    const [snackbarObj, setSnackbarObj] = useState({
        open: false,
        type: "success",
        snackbarMsg: "",
        onClose: () => {
            setSnackbarObj((prev) => {
                return {
                    ...prev,
                    open: false
                }
            })
        }
    })
    const token = getToken();
    const addNewField = (index) => {
        let tempArray = [...commissionArray];
        if ((tempArray[index].maximum === null || tempArray[index].maximum === "") || (tempArray[index].number === null || tempArray[index].number === "")) {
            return;
        }
        if (tempArray[index].maximum < tempArray[index].min) {
            return;
        }
        setCommissionArray((prev) => {
            return [...prev, {
                number: null,
                min: (parseInt(tempArray[index].maximum) + 1),
                maximum: null
            }]
        })

    }

    const removeSelectedField = (selectedIndex) => {
        let tempArray = [...commissionArray];
        tempArray = tempArray.filter((value, index) => index !== selectedIndex)
        setCommissionArray(() => {
            return [...tempArray];
        })
    }
    const handleChange = (index, event) => {
        let currentArray = [...commissionArray];
        currentArray[index] = {
            ...currentArray[index],
            [event.target.name]: event.target.value
        }
        if (index === 0) {
            currentArray[0] = {
                ...currentArray[0],
                min: "0"
            }
        }
        setCommissionArray(currentArray);
    }

    const getAdminCommission = async () => {
        try {
            const result = await getAdminSettings(token);
            if (result.status === 200) {
                if (result.data.is_success) {
                    if (result.data.data.commission.length < 1) {
                        setCommissionArray([{
                            number: null,
                            min: null,
                            maximum: null
                        }])
                    }
                    setCommissionArray(result.data.data.commission)
                }
            } else {
                setCommissionArray([{
                    number: null,
                    min: null,
                    maximum: null
                }])
                setSnackbarObj((prev) => {
                    return {
                        ...prev,
                        open: true,
                        snackbarMsg: "something went wrong while fetching commission data!",
                        type: "error"
                    }
                })
            }
        } catch (error) {
            setSnackbarObj((prev) => {
                return {
                    ...prev,
                    open: true,
                    snackbarMsg: "something went wrong while fetching commission data!",
                    type: "error"
                }
            })
        }
        setIsLoading(false)
    }
    const updateCommission = async (data) => {
        try {
            if (data.length > 0) {
                let lastIndex = (data.length) - 1;
                data[lastIndex] = {
                    ...data[lastIndex],
                    maximum: "0"
                }
            }
            for (const [index, value] of data.entries()) {
                data[index] = {
                    min: "" + (value.min),
                    maximum: "" + (value.maximum),
                    number: "" + (value.number)

                }
            }
            const result = await setTradesCommission(data, token);
            if (result.status === 200) {
                if (result.data.is_success) {
                    setSnackbarObj((prev) => {
                        return {
                            ...prev,
                            open: true,
                            type: "success",
                            snackbarMsg: "Commission updated Successfully"
                        }
                    })
                } else {
                    setSnackbarObj((prev) => {
                        return {
                            ...prev,
                            open: true,
                            snackbarMsg: "Fields cannot be Empty!",
                            type: "error"
                        }
                    })
                }
            } else {
                setSnackbarObj((prev) => {
                    return {
                        ...prev,
                        open: true,
                        snackbarMsg: "something went wrong",
                        type: "error"
                    }
                })
            }


        } catch (error) {
            setSnackbarObj((prev) => {
                return {
                    ...prev,
                    open: true,
                    snackbarMsg: "something went wrong",
                    type: "error"
                }
            })
        }
    }
    useEffect(() => {
        if (commissionArray.length < 1) {
            setCommissionArray([{
                number: null,
                min: null,
                maximum: null
            }])
        }
        getAdminCommission();
        //eslint-disable-next-line
    }, [])


    return (
        <Box>
            <CustomSnackbar {...snackbarObj} />
            {isLoading ? <Box display="flex" justifyContent="center" alignItems="center" height="150px"  ><CircularProgress className='text-blue' /> </Box> : <>
                <Box marginY={1} className='fs-12 fw-600 text-black'>Set Monthly Commission</Box>
                <Box className='fs-14 fw-300 text-grey'>You can change default commission percentage from here. </Box>
                <Box marginY={1}>
                    <Grid container>
                        <Grid Item xs={11} md={6}>
                            {commissionArray.map((value, index) => {
                                return (<Box key={index} marginY={1} width="100%" display="inline-flex">
                                    <Box display="inline-flex" alignItems="flex" alignSelf="center">
                                        <Box className='commission-input-size'>
                                            <Box className='fs-12 fw-600 text-grey'>Minimum&nbsp;Trades</Box>
                                            <Input type="number" disabled={true} name="min" fullWidth disableUnderline={true} className="theme-input-v2 px-1" value={index === 0 ? 0 : value.min} onChange={event => handleChange(index, event)} />
                                            {/* <Box className='text-error fs-14 '>error</Box> */}
                                        </Box>
                                        <Box marginX={1} display="flex" alignItems="center">
                                            <img src={greaterThanIcon} alt="" />
                                        </Box>
                                        <Box className='commission-input-size'>
                                            <Box className='fs-12 fw-600 text-grey'>Maximum&nbsp;Trades</Box>
                                            <Input type="number" name="maximum" fullWidth disableUnderline={true} className="theme-input-v2 px-1" value={value.maximum} onChange={event => handleChange(index, event)} />
                                            {/* <Box className='text-error fs-14'>error</Box> */}
                                        </Box>
                                        <Box className='commission-input-size-v2' marginLeft={2}>
                                            <Box className='fs-12 fw-600 text-grey'>Commission</Box>
                                            <Input type='number' name="number" fullWidth disableUnderline={true} className="theme-input-v2 px-1" value={value.number} endAdornment={<span className='fs-14 fw-600 text-grey-light'>$</span>} onChange={event => handleChange(index, event)} />
                                            {/* <Box className='text-error fs-14'>error</Box> */}
                                        </Box>
                                    </Box>
                                    <Box marginLeft={1} display="inline-flex" alignItems="center">
                                        {((commissionArray.length - 1)) === index || commissionArray.length < 2 ? <IconButton onClick={() => addNewField(index)}>
                                            <AddBox className='text-blue' />
                                        </IconButton> : null}

                                        {(commissionArray.length - 1) === index && index !== 0 ? <IconButton onClick={() => removeSelectedField(index)} >
                                            <RemoveCircle className='text-error' />
                                        </IconButton> : null}

                                    </Box>

                                </Box>)
                            })}

                        </Grid>
                    </Grid>
                    <Box marginY={1}>
                        <Button disableElevation variant='contained' className='bg-color-success text-white min-width-120' onClick={() => updateCommission(commissionArray)}>Update</Button>
                    </Box>
                </Box>
            </>}

        </Box >
    )
}

export default Commission