import Api from "./index";

  export const getProductFundsBalanceRequestList = async (payload,token) => {
    try {
      return await Api.get(`/api/v1/admin/product-funds/balance`, {
        params:{
            ...payload,
                  },
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };
  export const approvalBatchUpdate = async (payload,token) => {
    try {
      return await Api.post(`/api/v1/admin/product-funds/balance/approval`,{
        ...payload
      }, {
      
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };