import Cookies from "universal-cookie";

export const validateToken = () => {
  const cookies = new Cookies();
  const currentToken = cookies.get("token");
  if (typeof currentToken === "undefined") {
    return false;
  } else {
    return true;
  }
};
