import {
    Box,
    Button,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAnyOldDate } from "../funtions/dateFormatter";

import { validateToken } from "../funtions/validateToken";
import { setFromFilters, setTimeLabel, setToFilters } from "../../redux/actions/action";
import { useSelector } from "react-redux";

const CategoryFilter = ({ listItemName, trader }) => {
    let currentDate = new Date();
    let toDate = currentDate.toISOString()
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const anchorRef = useRef(null);
    let tokenStatus = validateToken()
    const currentFilter = useSelector((state) => state.filtersReducer.timeLabel);
    const dispatch = useDispatch()
    if (trader) {
        toDate = toDate.slice(0, 10);
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    const getAllUsers = async (filter) => {
        // dispatch(setUserListFilter(filter))
        // const result = await AllUsers(start, "", filter, token);
        // if (result.status === 200) {
        //     if (result.data.is_success) {
        //         dispatch(setUserListData(result.data.data.user))
        //     }
        // }
    };

    useEffect(() => {
        if (tokenStatus === true) {
            return;
        } else {

            navigate('/sign-in')
        }
        // eslint-disable-next-line
    }, [tokenStatus])


    return (
        <Box position="relative" className="z-index-high">
            <Button disableElevation variant="contained" className='fs-12 bg-color-blue-light text-blue h-32' endIcon={open ? <ExpandLess color='secondary' /> : <ExpandMore color='secondary' />} ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle} >{currentFilter}</Button>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >

                                    <MenuItem onClick={(event) => {
                                        let date = new Date()
                                        if (trader) {
                                            date = getAnyOldDate(30, false, "isShort");
                                        } else {
                                            date = getAnyOldDate(30, "isIso");
                                        }
                                        dispatch(setFromFilters(date));
                                        dispatch(setToFilters(toDate));
                                        dispatch(setTimeLabel("30 DAYS"));
                                        setOpen(false)
                                    }}>{listItemName || "30 Days"}</MenuItem>
                                    <MenuItem onClick={(event) => {
                                        let date = new Date()
                                        if (trader) {
                                            date = getAnyOldDate(7, false, "isShort");
                                        } else {
                                            date = getAnyOldDate(7, "isIso");
                                        }
                                        dispatch(setFromFilters(date));
                                        dispatch(setToFilters(toDate));
                                        dispatch(setTimeLabel("7 DAYS"));

                                        setOpen(false)
                                    }}>{listItemName || "7 Days"}</MenuItem>
                                    <MenuItem onClick={(event) => {
                                        let date = new Date()
                                        if (trader) {
                                            date = getAnyOldDate(1, false, "isShort");
                                        } else {
                                            date = getAnyOldDate(1, "isIso");
                                        }
                                        dispatch(setFromFilters(date));
                                        dispatch(setTimeLabel("TODAY"));
                                        dispatch(setToFilters(toDate));
                                        setOpen(false)
                                    }}>{listItemName || "Today"}</MenuItem>
                                    <MenuItem onClick={(event) => {
                                        dispatch(setFromFilters(null));
                                        dispatch(setToFilters(null));
                                        dispatch(setTimeLabel("ALL"));
                                        setOpen(false)
                                    }}>{listItemName || "All"}</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default CategoryFilter;
