import { Snackbar, ClickAwayListener } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react'
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// open is either true or false 
// type is string i.e success for green toast , error for red , warning for yellow 
// onClose  is a function that will handle closing logic 
const CustomSnackBar = ({ open, snackbarMsg, type, setSnackState }) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setSnackState((prev) => {
                return {
                    ...prev,
                    toggle: false
                }
            })
        }
    }
    return (
        <ClickAwayListener onClickAway={handleClose}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type || "success"}>
                {snackbarMsg || ""}
            </Alert>
        </Snackbar>
        </ClickAwayListener>
    )
}

export default CustomSnackBar