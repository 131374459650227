import {
    IconButton,
    DialogTitle,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
  } from "@material-ui/core";
  import { Close, ReportProblemOutlined } from "@material-ui/icons";
  import CustomSnackBar from "./CustomSnackBar";
  import { useState } from "react";
  
  const BanProfileModal = ({ open, handleClose, status, handleBan }) => {
  
    const [toastObj, setToastObj] = useState({
      open: false,
      snackbarMsg: "",
      type: "success",
      onClose: () => {
        setToastObj((prev) => {
          return {
            ...prev,
            open: false,
          };
        });
      },
    });
  
    return (
      <>
          <CustomSnackBar {...toastObj} />
      
        <Dialog
          PaperProps={{ className: "border-radius-15 " }}
          fullWidth={false}
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        ><DialogTitle id="max-width-dialog-title">
          <Box
            width="100%"
            display="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            className="fs-28 fw-700 text-blue-dark"
          >
            <span></span>
            <IconButton size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
          <DialogContent className="overflow-scroll-hidden">
           <Box minWidth ={"350px"} width={"100%"}> 
           <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"} marginX={1}>
                            <Box><ReportProblemOutlined fontSize="large"/></Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} marginLeft={3}>
                                <Box className='fw-700 fs-24 text-blue-dark'>Suspend User</Box>
                                <Box className='fw-500 fs-15 text-blue-dark'>{status === 'suspended' ? "Are you sure you want to unsuspend this user" : "Are you sure you want to suspend this user" }</Box>
                            </Box>
                            </Box>
           </Box>
            
        
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Box width={"100%"} display={"flex"} flexDirection={"column"}alignItems="center"
              justifyContent={"center"}>
            <Box
                marginY={1}
                width={"90%"}
            >
              <Button
                className=" h-40"
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => handleBan(status === 'suspended' ? 'active' : 'suspended')}>                Confirm
              </Button>
            </Box>
            <Box
                marginY={1}
                width={"90%"}
            >
              <Button
                className=" h-40"
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleClose}
              >
                No, cancel
              </Button>
            </Box></Box>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default BanProfileModal;