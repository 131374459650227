import { makeStyles, Box, Grid, Paper, Table,Input, TableBody, TableCell, TableHead, TableRow, TableContainer, Hidden, OutlinedInput, List, ListItem, Switch, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, FormControlLabel, CircularProgress, Snackbar, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@material-ui/icons';
import { singleUserData, } from '../api/user';
import { useSelector, useDispatch } from "react-redux";
import Pagination from '@material-ui/lab/Pagination';
import DashboardLayout from './layout/DashboardLayout';
import profilePic from "../assets/images/profile.svg"
import { singleUserTransaction, userTransactionsExcel } from '../api/transactions';
import { setUserTransactionList } from '../redux/actions/action';
import Cookies from 'universal-cookie/es6';
import { useParams } from 'react-router-dom';
import { SaveAlt,HighlightOff,CheckCircleOutline,AccessTime } from "@material-ui/icons";
import ColoredSpan from '../components/ColoredSpan';
import TransactionsSort from '../components/Filters/TransactionsSort'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});

const TradesDetails = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch()

    const [state, setState] = useState({
        checkedB: true,
    });

    const [start, setStart] = useState(0);
    const [sort, setSort] = useState('');
    const [status, setStatus] = useState("");
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState("");
    const [userData, setUserData] = useState({});
    const cookies = new Cookies();
    const token = cookies.get("token");
    const params = useParams()
    const userTransactionList = useSelector((state) => state.transactions.userTransactionsListObj);
    

    const formatDateTime = (dateTimeString) => {
        const dateObject = new Date(dateTimeString);

        // Format date using toLocaleString and DateTimeFormat options
        const formattedDateString = dateObject.toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Singapore',
        });
    
        // Format time and UTC information
        const formattedTimeString = dateObject.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'Asia/Singapore',
        });
    
        // Combine date and time with UTC information
        const finalFormattedDateTime = (
            <span>
              {formattedDateString} {formattedTimeString} (UTC +8 MY)
            </span>
          );    
        return finalFormattedDateTime;
      };


      const downloadAllClick = async() => {
        const response = await userTransactionsExcel(params.id, filter, sort, status, token )
        const url = response.data.data.url
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${userData.user_name}_transactions_list.xlsx`; // Specify the filename for the downloaded file
    
            // Append the link to the document and trigger a click
            document.body.appendChild(link);
            link.click();
    
            // Remove the link and revoke the URL after the download is initiated
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
          });
}

      const displayStatus = (status) => {
        switch (status) {
          case 'COMPLETE':
          case 'EXECUTED':
          case 'ACCEPTED':
                return <Box display={"inline-flex"} alignItems="center" >
                        <Box marginRight={-3}> <ColoredSpan color={"success"} background={"transparent"} fontSize={16}><CheckCircleOutline  fontSize='medium'/></ColoredSpan ></Box >
                        <Box><ColoredSpan color={"success"} background={"transparent"} fontSize={16}>Approved</ColoredSpan ></Box >
                    </Box >
          case 'CANCELED':
            return  <Box display={"inline-flex"} alignItems="center" >
                        <Box marginRight={-3}> <ColoredSpan color={"error"} background={"transparent"} fontSize={16}><HighlightOff  fontSize='medium'/></ColoredSpan ></Box >
                        <Box><ColoredSpan color={"error"} background={"transparent"} fontSize={16}>Denied</ColoredSpan ></Box >
                    </Box >
          case 'QUEUED':
          case 'FILLED':
          case 'PARTIALLY_FILLED':
          case 'APPROVAL_PENDING':
          case 'SENT_TO_CLEARING':
          case 'NEW':
            return  <Box display={"inline-flex"} alignItems="center" >
                        <Box marginRight={-3}> <ColoredSpan color={"warning"} background={"transparent"} fontSize={16}><AccessTime  fontSize='medium'/></ColoredSpan ></Box >
                        <Box><ColoredSpan color={"warning"} background={"transparent"} fontSize={16}>Pending</ColoredSpan ></Box >
                    </Box >
          default:
              return<Box className='fw-600 fs-14 text-blue-dark' align="center">{status}</Box>
        }
      };
      
    const getUserTransactions = async () => {
        const user = await singleUserData(params.id, token);
        if (user.status === 200) {
            setUserData(user.data.data)
        }
        const result = await singleUserTransaction(params.id,start, filter, sort, status, token);
        if (result.status === 200) {
            if (result.status === 200) {
                if (result.data.is_success) {
                    let transactionDataArr = result.data.data.transaction;
                    setPage(result.data.data.total_pages);
                    dispatch(setUserTransactionList(transactionDataArr))
                }
            }
        }
        setLoading(false);
    };
    useEffect(() => {
        getUserTransactions()
        //eslint-disable-next-line
    }, [state.checkedB])

    useEffect(() => {
        getUserTransactions()
        // eslint-disable-next-line
    }, [start, filter, sort, status])
    return <DashboardLayout>
        {loading ? <Box display={"flex"} justifyContent="center" alignItems={"center"} width={"100%"} height={"80vh"}> <CircularProgress color='secondary' /></Box> : <Grid className='mx-1' container spacing={2} >
            <Grid item xs={12}>
                    <Box display={"flex"} flexGrow={1} >
                        <Box display={"flex"} flexDirection="column" width={"100%"}>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-14 fw-500 text-grey'>Pages / Transactions / Transactions Management</Box>
                            </Box>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-24 fw-700 text-blue-dark'>Transactions Management</Box>
                            </Box>
                        </Box>
                    </Box>
            </Grid>

            <Grid item xs={12} md={12}   >
            <Paper className='px-1 border-radius-10' >
                    <Box className='h-400 overflow-hidden' width={"100%"} display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}  paddingX="20px">
                        <Box className='fs-24 fw-700 text-blue-dark'>Profile Information</Box>
                        <Box borderRadius={"8px"} paddingY="15px" display="inline-flex" justifyContent={"space-between"} width="100%" >
                        <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"}  paddingY={3}>
                            <Box>< img width="100px" height="100px" src={userData?.profile_image !== "null" ? userData.profile_image : profilePic} alt={userData?.name} /></Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} paddingX={3} >
                                <Box marginTop="12px" className='fw-700 fs-24 text-blue-dark'>{userData.name}</Box>
                                <Box marginTop="12px" className='fw-500 fs-15 text-blue-dark'>{userData._id}</Box>
                            </Box>
                            </Box>
                        </Box>
                        <Box  width={"100%"}>
                        <Divider/>
                        </Box>
                        <Box borderRadius={"8px"} paddingY="15px" display="inline-flex" justifyContent={"space-between"} width="100%" >
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <span className='fs-16 fw-500 text-black'>Username</span>
                                <span className='fs-16 fw-700 text-blue'>{userData.user_name}</span>
                            </Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <span className='fs-16 fw-500 text-black'>Email</span>
                                <span className='fs-16 fw-700 text-blue'>{userData.email}</span>
                            </Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <span className='fs-16 fw-500 text-black'>Phone Number</span>
                                <span className='fs-16 fw-700 text-blue'>-</span>
                            </Box>
                            </Box>
                    </Box>
                </Paper>
                <Paper className='px-1 py-1 my-2 border-radius-10'>
            <Box display="flex" paddingY={2} fontSize={20} fontWeight={700} color="secondary.dark">
                Transactions Management
            </Box>
            <Box display={"inline-flex"} width={"100%"} flexDirection="row" justifyContent={'space-between'}>
                <Box display={"inline-flex"} marginY={2} >
                    <Box marginRight={2} boxShadow={1} className="h-32  border-radius-5 bg-color-white"><Input disableUnderline placeholder='Search' inputProps={{ className: 'bg-color-white px-1 border-radius-5 ' }} endAdornment={<SearchOutlined className='text-grey-light' />} 
                    onChange={(event) => {
                        setFilter(event.target.value);
                        }} />
                    </Box>
                    <Box display="inline-flex"> <Box marginRight="10px"><TransactionsSort setSort={setSort} /></Box>
                {/* <Box><UserStatusFilter setStatus = {setStatus}/></Box> */}
                    </Box>
                </Box>
                <Box display={"inline-flex"} flexDirection="row" alignItems={"center"} justifyContent={"end"} paddingX={3} onClick={downloadAllClick} style={{cursor: 'pointer'}}>
                            <Box paddingX={1}><SaveAlt/></Box>
                            <Box className='fw-500 fs-20 text-blue-dark'>Download all</Box>
                </Box>
            </Box>
            <Grid item xs={12}>

                <Box >
                    <TableContainer className={classes.paper} component={Paper} >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    {/* <TableCell className='fs-12 fw-600 text-grey' >Name</TableCell> */}
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Order ID</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Amount in (MYR)</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Amount out (MYR)</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Type</TableCell>
                                    {/* Stocks/Funds/Cash */}
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Status</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Request Date</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userTransactionList.map((value, index) => (
                                    <TableRow key={index}>
                                        {/* <TableCell className='fw-500 fs-12 text-grey' align="left">{value.name}</TableCell> */}
                                        <TableCell className='fw-500 fs-10 text-grey' align="left">{value.orderId}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.amount_in.toFixed(2)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.amount_out.toFixed(2)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.type === 'USER BALANCE' ? 'CASH' : value.type}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {displayStatus(value.status)}
                                        </TableCell>
                                        <TableCell className='fw-600 fs-10 text-blue-dark' align="left">{formatDateTime(value.request_date)}</TableCell>
                                     </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box display={'flex'} justifyContent="flex-end" width={"100%"} my={3}>
                    <Box><Pagination
                        className="text-blue "
                        color="secondary"
                        showFirstButton={true}
                        showLastButton={true}
                        hideNextButton={false}
                        hidePrevButton={false}
                        count={page}
                        onChange={(e, index) => {
                            let newindex = index - 1;
                            setStart(newindex);
                        }}
                        />
                        </Box>
                </Box>
                </Grid>
            </Paper>
            </Grid>
        </Grid>
        }
    </DashboardLayout >;
};

export default TradesDetails;