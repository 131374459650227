// import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { changeMode, checkModeCounter } from '../redux/actions/action';
import CustomSnackBar from './CustomSnackBar';
import { getToken } from './funtions/getToken';

const ModeSwitch = () => {
    const globalMode = useSelector((state) => state.modeSwitch.mode);
    // const loadingCounter = useSelector((state) => state.modeSwitch.counter)
    const dispatch = useDispatch()
    const [ isLoading, setIsLoading] = useState(true);
    const [mode, setMode] = useState({
        checked: globalMode === "paper" ? false : true,
        modeName: globalMode === "paper" ? "Paper" : "real"
    })
    const token = getToken();
    const [snackbar, setSnackbar] = useState({
        msg: "",
        toggle: false,
        type: "success"
    })
    // const handleModeSwitch = () => {
    //     if (mode.checked === false) {
    //         dispatch(changeMode("real"))
    //         setMode((prev) => {
    //             return {
    //                 ...prev,
    //                 checked: true,
    //                 modeName: "real"
    //             }
    //         })
    //         return;
    //     }
    //     dispatch(changeMode("paper"))
    //     setMode((prev) => {
    //         return {
    //             ...prev,
    //             checked: false,
    //             modeName: "Paper"
    //         }
    //     })
    // }
    const getAdminMode = async () => {
        try {
            dispatch(changeMode("paper"))
            setMode((prev) => {
                return {
                    ...prev,
                    modeName: "Paper",
                    checked: false
                }
            })
        } catch (error) {

        }
        dispatch(checkModeCounter(2));
        setIsLoading(false);
    }
    useEffect(() => {
        getAdminMode();
    }, [])
    useEffect(() => {
        setTimeout(() => {

            setSnackbar((prev) => {
                return {
                    ...prev,
                    toggle: false,

                }
            })

        }, 1000)
    }, [mode])

    // const changeAdminMode = async () => {
    //     try {
    //         let newMode = mode.modeName === "Paper" ? "real" : "paper";
    //         const result = await adminModeSwitch(newMode, token);
    //         if (result.status === 200 || result.status === 202) {
    //             if (result.data.is_success) {
    //                 handleModeSwitch();
    //                 await window.location.reload(true);
    //                 setTimeout(() => {
    //                     setSnackbar((prev) => {
    //                         return {
    //                             ...prev,
    //                             toggle: true,
    //                             type: "success",
    //                             msg: "Mode Switched Successfully"
    //                         }
    //                     })
    //                 }, 1000)

    //             } else {
    //                 await setSnackbar((prev) => {
    //                     return {
    //                         ...prev,
    //                         toggle: true,
    //                         type: "error",
    //                         msg: result.data.message
    //                     }
    //                 })
    //             }
    //         } else {
    //             await setSnackbar((prev) => {
    //                 return {
    //                     ...prev,
    //                     toggle: true,
    //                     type: "error",
    //                     msg: "Something went wrong"
    //                 }
    //             })
    //         }
    //     } catch (error) {
    //         setSnackbar((prev) => {
    //             return {
    //                 ...prev,
    //                 toggle: true,
    //                 type: "error",
    //                 msg: "Something went wrong"
    //             }
    //         })
    //     }

    // }
    return (
        <>
            <CustomSnackBar open={snackbar.toggle} type={snackbar.type} snackbarMsg={snackbar.msg} />
        </>

    )
}

export default ModeSwitch