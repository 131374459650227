const initialState = {
  usersListObj: [],
  complianceListObj: [],
  start: 0,
};

const users = (state = initialState, action) => {
  if (action.type === "setUserListData") {
    state = {
      ...state,
      usersListObj: action.payload,
    };
    return state;
  } else if (action.type === "setComplianceListData") {
      state = {
        ...state,
        complianceListObj: action.payload,
      };
      return state;
  } else return state;
};

export default users;
