export const defaultGraphSetting = {
  options: {
   
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",    // curve style
    },
    colors: ["#005Bae", "#6CB5EE"],  // colors for lines/strokes

    xaxis: {
      tickPlacement:"on",  // between | on 
      categories: [],
      labels: {
        show: true,
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: -20,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="15">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    grid: {
      show: true,
      borderColor: "#84818A",
      strokeDashArray: 4,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 20,
      },
    },
  },
  series: [
    {
      name: "Trade Value",
      data: [],
    },
    {
      name: "AxeHedge Earnings",
      data: [],
    },
  ],
};
export const InvestmentGraphDefaultSetting ={
  options: {
    stroke: {
      curve: "smooth",    // curve style
    },
      xaxis: {
          categories: [],

          labels: {
              show: true,
              rotate: -45,
          }
      },
      legend: {
          show: true
      },
      grid: {
          show: true,
          borderColor: '#84818A',
          strokeDashArray: 4,
          position: 'back',
          xaxis: {
              lines: {
                  show: true
              }
          },
          yaxis: {
              lines: {
                  show: false
              }
          },
          row: {
              colors: undefined,
              opacity: 0.5
          },
          column: {
              colors: undefined,
              opacity: 0.5
          },
          padding: {
              top: 20,
              right: 0,
              bottom: 0,
              left: 20
          },
      },
      yaxis: {
          show: false,

      },
      chart: {
          toolbar: {
              show: true,
              offsetX: -20,
              offsetY: 0,
              tools: {
                  download: false,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: false | '<img src="/static/icons/reset.png" width="15">',
                  customIcons: []
              },
              export: {
                  csv: {
                      filename: undefined,
                      columnDelimiter: ',',
                      headerCategory: 'category',
                      headerValue: 'value',
                      dateFormatter(timestamp) {
                          return new Date(timestamp).toDateString()
                      }
                  },
                  svg: {
                      filename: undefined,
                  },
                  png: {
                      filename: undefined,
                  }
              },
              autoSelected: 'zoom'
          },
      }
  },
  series: [
      {
          name: "Total Invested Amount as Stocks",
          data: [0,0,0,0,0,0]
      },
      {
          name: "Total Invested Amount",
          data: [0,0,0,0,0,0]
      }
  ],
}
export const modifyGraphData = (settingObj,xAxisArray, yAxisArray1, yAxisArray2, names) => {
  let graphSetting = settingObj;
  graphSetting = {
    ...graphSetting,
    options:{
      ...graphSetting.options,
      xaxis: {
        ...graphSetting.options.xaxis,
        categories: xAxisArray,
        
      },
    },
    series: [
      {
        name: names?.investedAmount || "Trade Value",
        data: yAxisArray1 || [],
      },
      {
        name: names?.investedInStocks || "AxeHedge Earnings",
        data: yAxisArray2 || [],
      },
    ],
  };
  return graphSetting;
};
