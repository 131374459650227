import Cookies from "universal-cookie/es6";

export const getToken = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  return token;
};

export const deleteToken = () => {
  const cookies = new Cookies();
  cookies.remove("token");
};
