const initialState = {
    fundsArray:[]
  };
  
  const fundsReducer = (state = initialState, action) => {
    if (action.type === "updateFundsData") {
      state = {
        ...state,
        fundsArray: action.payload,
      };
      return state;
    }else {
      return state;
    }
  };
  
  export default fundsReducer;