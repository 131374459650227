import { makeStyles, Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button, Hidden, OutlinedInput, List, ListItem, Switch, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, FormControlLabel, CircularProgress, Snackbar, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DashboardLayout from './layout/DashboardLayout';
import profilePic from "../assets/images/profile.svg"
import { handleAllowCompliance, singleUserData } from '../api/user';
import Cookies from 'universal-cookie/es6';
import { useParams } from 'react-router-dom';
import { SaveAlt, PictureAsPdfOutlined,HighlightOff,CheckCircleOutline,AccessTime } from "@material-ui/icons";
import ColoredSpan from '../components/ColoredSpan';
import ComplianceModal from '../components/ComplianceModal';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});

const ComplianceDetails = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [allow, setAllow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reason, setReason] = useState('');
    const [state, setState] = useState({
        checkedB: true,
    });
    const [snackState, setSnackState] = useState({
        open: false,
        msg: null
    })
    const [statusDisplay, setStatusDisplay] = useState({
        statusColor:null,
        statusSymbol: null,
        statusValue: null
    })
    const [usersData, setUsersData] = useState({});
    const cookies = new Cookies();
    const token = cookies.get("token");
    const params = useParams()
    //eslint-disable-next-line
    const [transactionInput, setTransactionInput] = useState({
        id: params.id,
        strategies: "",
        stock: "",
    });

    const formatDateTime = (dateTimeString) => {
        const dateObject = new Date(dateTimeString);

        // Format date using toLocaleString and DateTimeFormat options
        const formattedDateString = dateObject.toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Singapore',
        });
    
        // Format time and UTC information
        const formattedTimeString = dateObject.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'Asia/Singapore',
        });
    
        // Combine date and time with UTC information
        const finalFormattedDateTime = (
            <span>
              {formattedDateString} {formattedTimeString} (UTC +8 MY)
            </span>
          );    
        return finalFormattedDateTime;
      };

    const displayStatus = (status) => {
      switch (status) {
        case "approved":
          return {
            statusColor:'success',
            statusSymbol: <CheckCircleOutline fontSize='medium'/>,
            statusValue: 'Approved'
          }
        case "denied":
            return {
                statusColor:'error',
                statusSymbol: <HighlightOff fontSize='medium'/>,
                statusValue: 'Denied'
              }
        case "pending":
            return {
                statusColor:'warning',
                statusSymbol: <AccessTime fontSize='medium'/>,
                statusValue: 'Pending'
              }
        default:
            return null
      }
    };
    
    const downloadAllClick = async () => {
        const zip = new JSZip();

        
        for (const doc of usersData.userTrader.compliance.documents) {
            try {
                const response = await fetch(doc.url);
                const blob = await response.blob();

                // Extract filename from URL
                const filename = doc.file
                // Add the file to the zip archive
                zip.file(filename, blob);
            } catch (error) {
                console.error(`Error downloading file from ${doc.url}:`, error);
            }
        }

        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: 'blob' });
  
        // Creating the zip file
        saveAs(zipBlob, `compliance_${usersData.user_name}.zip`);
    }
    const downloadClick = (file) => {
            const url = file.url; // Replace with your actual file URL
            fetch(url)
              .then(response => response.blob())
              .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = file.file; // Specify the filename for the downloaded file
        
                // Append the link to the document and trigger a click
                document.body.appendChild(link);
                link.click();
        
                // Remove the link and revoke the URL after the download is initiated
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
              })
              .catch(error => {
                console.error('Error downloading file:', error);
              });
    }


    const handleClose = () => {
        getUserData()
        setOpen(false);
    };
    const handleCompliance = (allow) => {
        handleAllowCompliance(params.id, allow, reason, token)
        getUserData()
        setOpen(false);
    };
    const handleAllowOpen = (allow) => {
        setAllow(allow)
        setOpen(true);
    };
    const getUserData = async () => {
        const result = await singleUserData(params.id, token);
        if (result.status === 200) {
            if (result.data.is_success) {
                let userData = result.data.data;
                setStatusDisplay(displayStatus(userData.userTrader.compliance.status))
                setState((prev) => {
                    return {
                        ...prev,
                        checkedB: userData.active
                    }
                })
                setUsersData(userData)
            }
        }
        setLoading(false);
    };
    useEffect(() => {
        getUserData()
        // setStatusDisplay(displayStatus("pending"))
        //eslint-disable-next-line
    }, [state.checkedB])
    useEffect(() => {
        getUserData()
        //eslint-disable-next-line
    }, [open])
    return <DashboardLayout>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackState.open}
            onClose={handleClose}
            message={snackState.msg}
        />
        {loading ? <Box display={"flex"} justifyContent="center" alignItems={"center"} width={"100%"} height={"80vh"}> <CircularProgress color='secondary' /></Box> : <Grid className='mx-1' container spacing={2} >
            <Grid item xs={12}>
                    <Box display={"flex"} flexGrow={1} >
                        <Box display={"flex"} flexDirection="column" width={"100%"}>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-14 fw-500 text-grey'>Pages / Compliance / Profile</Box>
                            </Box>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-24 fw-700 text-blue-dark'>Profile</Box>
                            </Box>
                        </Box>
                    </Box>
            </Grid>

            <Grid item xs={12} md={12}   >
                <Paper className='px-1 border-radius-10' >
                    <Box className='h-400 overflow-hidden' width={"100%"} display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}  paddingX="20px">
                        <Box className='fs-24 fw-700 text-blue-dark' paddingTop={3}>Profile Information</Box>
                        <Box borderRadius={"8px"} paddingY="15px" display="inline-flex" justifyContent={"space-between"} width="100%" >
                        <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"}  paddingY={3}>
                            <Box>< img width="100px" height="100px" src={usersData?.profile_image !== "null" ? usersData.profile_image : profilePic} alt={usersData?.name} /></Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} paddingX={3} >
                                <Box marginTop="12px" className='fw-700 fs-24 text-blue-dark'>{usersData.name}</Box>
                                <Box marginTop="12px" className='fw-500 fs-15 text-blue-dark'>{usersData.userTrader.account_id}</Box>
                            </Box>
                            </Box>
                            <Box display={"inline-flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"} >
                            <Box display={"inline-flex"} alignItems="center" >
                                                <Box marginRight={-3}><ColoredSpan color={statusDisplay.statusColor} background={"transparent"} fontSize={16}>{statusDisplay.statusSymbol}</ColoredSpan ></Box>
                                                <Box><ColoredSpan color={statusDisplay.statusColor} background={"transparent"} fontSize={16}>{statusDisplay.statusValue}</ColoredSpan ></Box>
                                            </Box >
                            <Box display={"inline-flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"} onClick={downloadAllClick} style={{cursor: 'pointer'}}>
                                <Box paddingX={1}><SaveAlt/></Box>
                                <Box className='fw-500 fs-20 text-blue-dark'>Download all</Box>
                            </Box>
                            </Box>
                        </Box>
                        <Box  width={"100%"}>
                        <Divider fullWidth/>
                        </Box>
                        <Box borderRadius={"8px"} paddingY="15px" display="inline-flex" justifyContent={"space-between"} width="100%" >
                        
                        <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} paddingBottom={2}>
                                <span className='fs-16 fw-500 text-black'>Username</span>
                                <span className='fs-16 fw-700 text-blue'>{usersData.user_name ? usersData.user_name : '-'}</span>
                            </Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <span className='fs-16 fw-500 text-black'>Date of Birth</span>
                                <span className='fs-16 fw-700 text-blue'>{usersData.userTrader.date_of_birth ? usersData.userTrader.date_of_birth : '-' }</span>
                            </Box>
                        </Box>

                        <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} paddingBottom={2}>
                                    <span className='fs-16 fw-500 text-black'>Email</span>
                                    <span className='fs-16 fw-700 text-blue'>{usersData.email ? usersData.email : '-'}</span>
                                </Box>
                                <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                    <span className='fs-16 fw-500 text-black'>Phone Number</span>
                                    <span className='fs-16 fw-700 text-blue'>{usersData.userTrader.phone_number ? usersData.userTrader.phone_number : '-'}</span>
                                </Box>  
                            </Box>
                        </Box>

                        <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} paddingBottom={2}>
                                <span className='fs-16 fw-500 text-black'>Application Date</span>
                                <span className='fs-16 fw-700 text-blue'>{formatDateTime(usersData.userTrader.compliance.createdAt)}</span>
                            </Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                    <span className='fs-16 fw-500 text-black'>Resolvement Date</span>
                                    <span className='fs-16 fw-700 text-blue'>{usersData.userTrader.compliance.resolved_at ? formatDateTime(usersData.userTrader.compliance.resolved_at) : '-'}</span>
                            </Box>
                        </Box>

                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"start"}>
                                <span className='fs-16 fw-500 text-black'>Approved/Denied by</span>
                                <span className='fs-16 fw-700 text-blue'>{usersData.userTrader.compliance.resolved_by ? usersData.userTrader.compliance.resolved_by : '-'}</span>
                            </Box>
                        </Box>
                        
                    </Box>
                </Paper>
                {usersData.userTrader.compliance.status === 'pending' ? <Box></Box> : <Paper className='my-2 px-1 border-radius-10 overflow-scroll-hidden' >
                <Box className='h-400 overflow-hidden' width={"100%"} display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"start"}  paddingX="20px">
                    <Box className='fs-24 fw-700 text-blue-dark' paddingY={2}>Reason to {usersData.userTrader.compliance.status === 'approved' ? 'approve' : 'deny' }</Box>
                        <Box className='fs-14 fw-600 text-grey' width={"100%"} padding={2} sx={{backgroundColor: "whitesmoke",borderRadius:4, minHeight:300}}>
                        {usersData.userTrader.compliance.reason}
                        </Box>
                    </Box>
                </Paper>}
                <Paper className='my-2 px-1 border-radius-10 overflow-scroll-hidden  ' >
                <Box>
                <Box className='fs-24 fw-700 text-blue-dark' padding={2}>Identification</Box>
                        {usersData.complianceList.identification ? 
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell className='fs-14 fw-600 text-grey' >File</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Date modified</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {usersData.complianceList.identification.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='fw-500 fs-14 text-blue-dark' align="left"><Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"start"}><Box paddingRight={2}><PictureAsPdfOutlined/></Box>{value.file}</Box></TableCell>
                                        <TableCell className='fw-600 fs-10 text-blue-dark' align="left">{formatDateTime(value.timestamp)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-grey' align="left" onClick={() => downloadClick(value)} style={{cursor: 'pointer'}}><SaveAlt/></TableCell>
                                    </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                                :<Box className='fs-14 fw-600 text-grey my-2' align="center">No files available</Box>}
                </Box>
                </Paper>

                <Paper className='my-2 px-1 border-radius-10 overflow-scroll-hidden  ' >
                <Box >
                <Box className='fs-24 fw-700 text-blue-dark' padding={2}>Bank Statement</Box>
                        {usersData.complianceList.bank_statement ? 
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell className='fs-14 fw-600 text-grey' >File</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Date modified</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {usersData.complianceList.bank_statement.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='fw-500 fs-14 text-blue-dark' align="left"><Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"start"}><Box paddingRight={2}><PictureAsPdfOutlined/></Box>{value.file}</Box></TableCell>
                                        <TableCell className='fw-600 fs-10 text-blue-dark' align="left">{formatDateTime(value.timestamp)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-grey' align="left" onClick={() => downloadClick(value)} style={{cursor: 'pointer'}}><SaveAlt/></TableCell>
                                    </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                                :<Box className='fs-14 fw-600 text-grey my-2' align="center">No files available</Box>}
                
                </Box>
                </Paper>

                <Paper className='my-2 px-1 border-radius-10 overflow-scroll-hidden  ' >
                <Box >
                <Box className='fs-24 fw-700 text-blue-dark' padding={2}>Proof of Income</Box>
                        {usersData.complianceList.proof_of_income ? 
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell className='fs-14 fw-600 text-grey' >File</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Date modified</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {usersData.complianceList.proof_of_income.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='fw-500 fs-14 text-blue-dark' align="left"><Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"start"}><Box paddingRight={2}><PictureAsPdfOutlined/></Box>{value.file}</Box></TableCell>
                                        <TableCell className='fw-600 fs-10 text-blue-dark' align="left">{formatDateTime(value.timestamp)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-grey' align="left" onClick={() => downloadClick(value)} style={{cursor: 'pointer'}}><SaveAlt/></TableCell>
                                    </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                                :<Box className='fs-14 fw-600 text-grey my-2' align="center">No files available</Box>}
                
                </Box>
                </Paper>

                <Paper className='my-2 px-1 border-radius-10 overflow-scroll-hidden' >
                <Box className='h-400 overflow-hidden' width={"100%"} display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"start"}  paddingX="20px">
                    <Box className='fs-24 fw-700 text-blue-dark' paddingY={2}>Additional Disclaimer</Box>
                        <Box className='fs-14 fw-600 text-grey' width={"100%"} padding={2} sx={{backgroundColor: "whitesmoke",borderRadius:4, minHeight:300}}>
                        {usersData.userTrader.compliance.disclaimer}
                        </Box>
                    </Box>
                </Paper>

                <Paper className='my-2 px-1 border-radius-10 overflow-scroll-hidden  ' >
                <Box >
                <Box className='fs-24 fw-700 text-blue-dark' padding={2}>Supporting Documents</Box>
                        {usersData.complianceList.others ? 
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell className='fs-14 fw-600 text-grey' >File</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Date modified</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {usersData.complianceList.others.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='fw-500 fs-14 text-blue-dark' align="left"><Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"start"}><Box paddingRight={2}><PictureAsPdfOutlined/></Box>{value.file}</Box></TableCell>
                                        <TableCell className='fw-600 fs-10 text-blue-dark' align="left">{formatDateTime(value.timestamp)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-grey' align="left" onClick={() => downloadClick(value)} style={{cursor: 'pointer'}}><SaveAlt/></TableCell>
                                    </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                                :<Box className='fs-14 fw-600 text-grey my-2' align="center">No files available</Box>}
                </Box>
                </Paper>
                { usersData.userTrader.compliance.status === "pending" ?
                <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"end"}>
                                <Box paddingRight={2}><span className='fs-12 fw-400 text-grey'>The documents need consent to approve or deny by the admin.</span></Box>
                                <Box paddingRight={1} className='my-2' width={"20%"}><Button className=' h-40' fullWidth color="secondary" variant="contained" onClick={() => handleAllowOpen('approved')}>Approve</Button></Box>
                                <Box paddingLeftt={1} className='my-2' width={"20%"}><Button className=' h-40' fullWidth color="Primary" variant="contained" onClick={() => handleAllowOpen('denied')}>Deny</Button></Box>
                </Box> : <Box></Box>}
                <ComplianceModal open={open} handleClose={handleClose} allow= {allow} handleCompliance={handleCompliance} setReason={setReason} reason={reason}/>
            </Grid>
        </Grid>
        }
    </DashboardLayout >;
};

export default ComplianceDetails;