import { makeStyles, Box, Grid, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import DashboardLayout from '../../src/pages/layout/DashboardLayout';
import Cookies from 'universal-cookie/es6';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import dailyWeekly from "../assets/images/DailyWeekly Thumbnail.png"
import quickTakes from "../assets/images/QuickTakes Thumbnail.png"
import analystInsights from "../assets/images/AnalystInsights Thumbnail.png"
import eduGuides from "../assets/images/EduGuides Thumbnail.png"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});

const ContentManagement = () => {
    const navigate = useNavigate()
    const cookies = new Cookies();
    const token = cookies.get("token");
    const params = useParams()
    //eslint-disable-next-line
    const [transactionInput, setTransactionInput] = useState({
        id: params.id,
        strategies: "",
        stock: "",
    });

    return <DashboardLayout>
        <Grid className='mx-1' container spacing={2} >
            <Grid item xs={12}>
                    <Box display={"flex"} flexGrow={1} >
                        <Box display={"flex"} flexDirection="column" width={"100%"}>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-14 fw-500 text-grey'>Pages / Content Management</Box>
                            </Box>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-24 fw-700 text-blue-dark'>Content Management</Box>
                            </Box>
                        </Box>
                    </Box>
            </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1}>
            <Grid item xs={12} md={5} justifyContent="flex-start">
                <img src={dailyWeekly} alt="Daily Weekly News" onClick={() => navigate(`daily-weekly-news`)} style={{ maxWidth: '95%', height: '95%' }}/>
            </Grid>
            <Grid item xs={12} md={6} justifyContent="flex-start">
                <img src={quickTakes} alt="Quick Takes" onClick={() => navigate(`quick-takes`)} style={{ maxWidth: '95%', height: '95%' }}/>
            </Grid>
            <Grid item xs={12} md={6} justifyContent="flex-start">
                <img src={analystInsights} alt="Analyst Insights" onClick={() => navigate(`analyst-insights`)} style={{ maxWidth: '95%', height: '95%' }}/>
            </Grid>
            <Grid item xs={12} md={5} justifyContent="flex-end">
                <img src={eduGuides} alt="Educational Guides" onClick={() => navigate(`educational-guides`)} style={{ maxWidth: '95%', height: '95%' }}/>
            </Grid>
        </Grid>
    </DashboardLayout >;
};

export default ContentManagement;