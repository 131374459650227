import Api from "./index";

export const setTradesCommission = async (commissions, token) => {
  try {
    return await Api.patch(
      `/api/v1/admin/setting/commission`,
      {
        commissions,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const getAdminSettings = async (token) => {
  try {
    return await Api.get(`/api/v1/admin/setting`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
