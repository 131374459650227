import { Box, Grid, Paper, CircularProgress, Tooltip, Avatar, Divider } from '@material-ui/core';
import { FiberManualRecord, TrendingDown, TrendingUp } from '@material-ui/icons';
import React from 'react';
import DashboardLayout from '../pages/layout/DashboardLayout';
import Chart from "react-apexcharts";
import { useState, useEffect } from 'react';
import Cookies from "universal-cookie"
import { useNavigate } from "react-router-dom"
import { fundsGraphData, totalSuccessfulTradesCount, totalUserCount } from '../api/Dashboard';
import { currencyFormatter } from './funtions/currencyFormatter';
import usersCountIcon from "../assets/images/TotalUsersIcon.svg"
import tradesCountIcon from "../assets/images/tradersIcon.svg"
import successfulTradesIcon from "../assets/images/succussfulTradesIcon.svg"
import { defaultGraphSetting, InvestmentGraphDefaultSetting, modifyGraphData } from '../config/graphSettings';
import { dayMonthFormat } from './funtions/dateFormatter';
import StocksUnderManagement from './StocksUnderManagement';

const Dashboard = () => {
    const cookies = new Cookies()
    const token = cookies.get('token')
    const navigate = useNavigate()
    const [dashBoardData, setDashboardData] = useState({
        totalUserCount: 0,
        totalTraders: 0,
        pendingTraders: 0,
        successfulTrades: 0,
        todaysSuccessfulTrades: 0
    })
    const [investedAmount, setInvestedAmount] = useState({})
    const [fundsData, setFundsData] = useState({});
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [data2, setData2] = useState(defaultGraphSetting)
    const [data, setData] = useState(InvestmentGraphDefaultSetting)

    const getFundsGraphData = async () => {
        try {
            let payload = {
                status: "filled",
                transaction_by: "individuals"
            }
            const result = await fundsGraphData(payload, token);
            if (result.status === 200) {
                if (result.data.is_success) {
                    let graph = (result.data.data.graph).sort()
                    let xAxisArray = [];
                    let tradesArray = [];
                    let adminProfitArray = []
                    graph.forEach((value) => {
                        xAxisArray.push(dayMonthFormat(new Date(value._id)));
                        tradesArray.push(value.all_total_amount.toFixed(2));
                        adminProfitArray.push(value.all_admin_profit.toFixed(2));
                    })
                    setData2(modifyGraphData(defaultGraphSetting, xAxisArray, tradesArray, adminProfitArray));
                    setFundsData(result.data.data)

                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!token) {
            navigate('/sign-in')
        }
        // eslint-disable-next-line
    }, [])
    //------ API Functions ------->
    const getAllCounts = async () => {
        const result = await totalUserCount((new Date()).toISOString(), token);
        if (result.status === 200) {
            if (result.data.is_success) {
                setDashboardData((prev) => {
                    return {
                        ...prev,
                        totalUserCount: result.data.data.users.length,
                        totalTraders: result.data.data.active_traders,
                        pendingTraders: result.data.data.pending_traders,
                        successfulTrades: result.data.data.successful_trades_count,
                        todaysSuccessfulTrades: result?.data?.data?.successful_trades_last_24hours_count
                    }

                })
            }
        }
    }
    const geTotalInvestedAmount = async () => {
        try {
            const result = await totalSuccessfulTradesCount(null, token);
            if (result.status === 200) {
                if (result.data.is_success) {
                    let graph = result.data.data.investmentGraph
                    let names = { investedAmount: "Total Invested Amount", investedInStocks: "Total Trade Volume" }
                    let xAxisArray = [];
                    let InvestedFundsArray = [];
                    let InvestedInStocksArray = []
                    graph.forEach((value) => {
                        xAxisArray.push(dayMonthFormat(new Date(value._id)));
                        InvestedFundsArray.push(value.total_investment?.toFixed(2));
                        InvestedInStocksArray.push(value.total_trade_value?.toFixed(2));
                    })
                    setData(modifyGraphData(InvestmentGraphDefaultSetting, xAxisArray, InvestedFundsArray, InvestedInStocksArray, names));
                    setInvestedAmount(result.data.data)
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(true)
        }
    }
    //----------------------------------------------------------




    useEffect(() => {
        getFundsGraphData();
        getAllCounts();
        geTotalInvestedAmount();
        // eslint-disable-next-line
    }, [])

    return <DashboardLayout>
        {loading ? <Box display={"flex"} justifyContent="center" alignItems={"center"} width={"100%"} height={"80vh"}> <CircularProgress color='secondary' /></Box> :
            <>
                <Box width="100%" margin={1} className='fs-24 fw-700 text-blue-dark'>
                    Dashboard
                </Box>
                <Grid item xs={12} md={4} >

                    <Paper elevation={0} className='border-radius-10 animate shadow' onClick={() => navigate("/users")} >
                        <Box marginX={2} className='h-200' display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="flex-start" >
                            <Box marginBottom={1} className='border-radius-round bg-color-blue-light avatar-h-4' display={"flex"} alignItems={"center"} justifyContent={'center'} padding={1}> <Avatar className='avatar-h-2 text-purple' src={usersCountIcon} /></Box>
                            <Box className='fs-40 fw-600 text-black'>{dashBoardData.totalUserCount ? dashBoardData.totalUserCount : 0}</Box>
                            <Box className='fs-14 text-purple fw-500' >Total Users</Box>
                        </Box>
                    </Paper></Grid>
                <Grid item xs={12} md={4} >
                    <Paper elevation={0} className='border-radius-10 animate shadow' onClick={() => navigate("/requests")} >

                        <Box marginX={2} className='h-200' display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="flex-start" >
                            <Box marginBottom={1} width="100%" display="inline-flex" justifyContent="space-between" alignItems="center">
                                <Box className='border-radius-round bg-success-light avatar-h-4' display={"flex"} alignItems={"center"} justifyContent={'center'} padding={1}> <img className='avatar-h-2 text-blue ' src={tradesCountIcon} alt="success" /> </Box>
                                <Box display="flex" flexDirection="column" textAlign={"right"}  >
                                    <Box className='fs-20 fw-600 text-blue'>
                                        {dashBoardData.pendingTraders}
                                    </Box>
                                    <Box className='fs-14 fw-500 text-black' >
                                        Pending request
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='fs-40 fw-600 text-black'>{dashBoardData.totalTraders}</Box>
                            <Box className='fs-14 text-blue fw-500' >Traders</Box>
                        </Box>
                    </Paper></Grid>
                <Grid item xs={12} md={4} >
                    <Paper elevation={0} className='border-radius-10 animate shadow' onClick={() => navigate("/trades")} >
                        <Box marginX={2} className='h-200' display={"flex"} flexDirection="column" justifyContent={"center"} alignItems="flex-start" >
                            <Box marginBottom={1} width="100%" display="inline-flex" justifyContent="space-between" alignItems="center">
                                <Box className='border-radius-round bg-success-light avatar-h-4' display={"flex"} alignItems={"center"} justifyContent={'center'} padding={1}> <img className='avatar-h-2 ' src={successfulTradesIcon} alt="success" /> </Box>
                                <Box display="flex" flexDirection="column" textAlign={"right"}  >
                                    <Box className='fs-20 fw-600 text-blue'>
                                        {dashBoardData.todaysSuccessfulTrades}
                                    </Box>
                                    <Box className='fs-14 fw-500 text-black' >
                                        In last 24 hours
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='fs-40 fw-600 text-black'>{dashBoardData.successfulTrades}</Box>
                            <Box className='fs-14 text-success fw-500' >Successful Trades</Box>
                        </Box>
                    </Paper></Grid>
                <Grid item xs={12} md={8} >
                    <Paper elevation={0} className="border-radius-10 px-1  h-530 shadow" >
                        <StocksUnderManagement />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} ><Paper elevation={0} className='border-radius-10 px-1  h-530 shadow'>
                    {/* <Box className='mx-1' display={"flex"} justifyContent="space-between" paddingY={3} ><Box className='fw-700 fs-18 text-blue-dark'>Statistics </Box><Button endIcon={<ExpandMoreIcon />} className={"bg-color-blue-light text-blue px-1 h-32"}>Weekly</Button></Box> */}
                    <Box className='mx-1' display={"flex"} justifyContent="space-between" paddingY={3} ><Box className='fw-700 fs-18 text-blue-dark'>Total Funds Under Management </Box></Box>

                    <Box className='mx-1' display={"inline-flex"} alignItems="center"><Box display={"flex"} alignItems="center"><FiberManualRecord className='text-blue fs-12' /></Box><Box className='fs-16 fw-500 text-black mx-5px'>Total Invested Amount</Box></Box>
                    <Tooltip title={`$${currencyFormatter(investedAmount?.total_investment)}`} placement="bottom"><Box component="div" sx={{ textOverflow: 'ellipsis', overflow: "hidden", }} className='fs-32 fw-600 text-black px-2' >${investedAmount?.total_investment ? currencyFormatter(investedAmount.total_investment) : 0}</Box></Tooltip>
                    <Box className='mx-1' marginTop={2} display={"inline-flex"} alignItems="center"><Box display={"flex"} alignItems="center"><FiberManualRecord className='text-success fs-12' /></Box><Box className='fs-16 fw-500 text-black mx-5px'>Total Trade Volume</Box></Box>
                    <Tooltip title={`$${currencyFormatter(investedAmount?.total_trade_value)}`} placement="bottom"><Box component="div" sx={{ textOverflow: 'ellipsis', overflow: "hidden", }} className='fs-32 fw-600 text-black px-2' >${investedAmount?.total_trade_value ? currencyFormatter(investedAmount.total_trade_value) : 0}</Box></Tooltip>
                    <Box className=' scrollable-div mx-5px  ' marginY={6} display={"flex"} justifyContent="center" alignItems={"center"} >
                        <Chart options={data.options}
                            series={data.series}
                            type="line"
                            width={360}
                            height={230}
                        />
                    </Box>
                </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0} className='border-radius-10 px-1 overflow-scroll-hidden h-530 shadow'>
                        {/* <Box className='mx-1' display={"flex"} justifyContent="space-between" paddingY={3} ><Box className='fw-700 fs-18 text-blue-dark'>Trading and Earnings </Box><Button endIcon={<ExpandMoreIcon />} className={"bg-color-blue-light text-blue px-1 h-32"}>Weekly</Button></Box> */}
                        <Box className='mx-1' display={"flex"} justifyContent="space-between" paddingY={3} ><Box className='fw-700 fs-18 text-blue-dark'>Trades and AxeHedge Earning </Box></Box>

                        <Box marginLeft={3} display="inline-flex " width={"100%"}>
                            <Box > <Chart options={data2.options}
                                series={data2.series}
                                type='line'
                                width="820"
                                height={400} /></Box>

                            <Box className='px-2' display={"flex"} flexDirection="column" justifyContent={"space-evenly"} width={"100%"} >
                                <Box display={"flex"} flexDirection="column" width={"100%"}>
                                    <Box className='mx-1' display={"inline-flex"} justifyContent="space-between" alignItems="center"><Box display={"inline-flex"} alignItems="center" width={"100%"}><Box display={"flex"} alignItems="center"><FiberManualRecord className='text-blue fs-12' /></Box><Box className='fs-16 fw-500 text-grey mx-5px'>Trade Value</Box></Box><Box visibility={"hidden"} display={"inline-flex"} alignItems="flex-end" ><TrendingUp className='text-success ' /><Box className='fs-14 fw-500 text-success'>&nbsp;4.07%</Box></Box></Box>
                                    <Box className='fs-40 fw-600 text-black px-2'>{`$${currencyFormatter(fundsData?.overall_data?.overall_total_amount)}`}</Box>
                                </Box>
                                <Divider />
                                <Box display={"flex"} flexDirection="column" width={"100%"} >
                                    <Box className='mx-1' display={"inline-flex"} justifyContent="space-between" alignItems="center"><Box display={"inline-flex"} alignItems="center" width={"100%"}><Box display={"flex"} alignItems="center"><FiberManualRecord className='text-blue-light fs-12' /></Box><Box className='fs-16 fw-500 text-grey mx-5px'>AxeHedge Earnings</Box></Box><Box visibility={"hidden"} display={"inline-flex"} alignItems="flex-end" ><TrendingDown className='text-error' /><Box className='fs-14 fw-500 text-error'>&nbsp;4.07%</Box></Box></Box>
                                    <Box className='fs-40 fw-600 text-black px-2'>{`$${currencyFormatter(fundsData?.overall_data?.overall_admin_profit)}`}</Box>
                                </Box>
                            </Box>
                        </Box>
                    </Paper></Grid>
            </>
        }

    </DashboardLayout>;
};

export default Dashboard;
