import {
    Box,
    Button,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../funtions/validateToken";

const NewsStatusFilter = ({ setFilter }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const anchorRef = useRef(null);
    let tokenStatus = validateToken()
    // const token = getToken();
    const [sortLabel, setSortLabel] = useState("Status: All");
    const dispatch = useDispatch()
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (tokenStatus === true) {
            return;
        } else {
            navigate('/sign-in')
        }
        // eslint-disable-next-line
    }, [tokenStatus])


    return (
        <Box position="relative" className="z-index-high">
            <Button disableElevation variant="contained" className='fs-12 bg-color-blue-light text-blue h-32' endIcon={open ? <ExpandLess color='secondary' /> : <ExpandMore color='secondary' />} ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle} >{sortLabel}</Button>

            <Popper

                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem onClick={(event) => {
                                            setFilter("")
                                            setSortLabel("Status: All")
                                            setOpen(false)
                                        }}>All</MenuItem>
                                        <MenuItem onClick={(event) => {
                                            setFilter("new")
                                            setSortLabel("Status: New")
                                            setOpen(false)
                                        }}>New</MenuItem>
                                        <MenuItem onClick={(event) => {
                                            setFilter("published")
                                            setSortLabel("Status: Published")
                                            setOpen(false)
                                        }}>Published</MenuItem>
                                        <MenuItem onClick={(event) => {
                                            setFilter("unpublished")
                                            setSortLabel("Status: Unpublished")
                                            setOpen(false)
                                        }}>Unpublished</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default NewsStatusFilter;