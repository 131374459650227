import axios from "axios";
import { deleteToken } from "../components/funtions/getToken";
//FIXME: Use process.env url for production
const Api = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  // baseURL: "https://dev.api.gmeez.com/api/v1",
  // baseURL: "http://localhost:8000/",
});

Api.interceptors.response.use(
  (response) => {
    // if 2fa failed, kick user out.
    if(response.status === 202 && response.data.message === "Please Verify by 2FA") {
      deleteToken();
      window.location.replace("/");
    } else {
      return response;
    }

  },
  (error) => {
    if (error.response.status === 401) {
      deleteToken();
      window.location.replace("/");
    }
  }
);
export default Api;
