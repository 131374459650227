const initialState = {
    start: 0,
    page:0,
    rePopulateData:false
  };
  
  const paginationReducer = (state = initialState, action) => {
    if (action.type === "setPageStart") {
      state = {
        ...state,
        start: action.payload,
      };
      return state;
    } else if (action.type === "setPageCount") {
        state = {
          ...state,
          page: action.payload,
        };
        return state;
      } 
      else if (action.type === "resetPagination") {
        state = {
          start: 0,
          page:0,
          rePopulateData:false
        };
        return state;
      } else if (action.type === "repopulate") {
        state = {
          ...state,
          rePopulateData:!state.rePopulateData
        };
        return state;
      }
      else return state;
  };
  
  export default paginationReducer;
  