
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, Divider, Grid, Paper, Snackbar } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
// import { useNavigate, useParams } from "react-router-dom";
// import Cookies from "universal-cookie";
import { useEffect } from "react";
import DashboardLayout from "./layout/DashboardLayout";
import ResetPassword from "../components/ResetPassword";
import Commission from "../components/Commission";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <div p={7}>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function Settings() {
    const [value, setValue] = useState(0);
    // const params = useParams();
    // const cookies = new Cookies();
    // const token = cookies.get("token");
    const [snackBar, setSnackBar] = useState({
        value: "",
        status: false,
    });
    // const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        let index = localStorage.getItem("selectedIndex");
        if (index > 0) {
            setValue(2);
        }
        localStorage.setItem("selectedIndex", 0);
    }, []);

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackBar.status}
                onClose={() =>
                    setSnackBar((prev) => {
                        return { ...prev, status: false };
                    })
                }
                message={snackBar.value}
            />
            <DashboardLayout >
                <Box width="100%">
                    <Box marginY={2} className="inline-flex space-btw item-center mx-2">

                        <label className="fs-24 fw-700 text-blue-dark">
                            Settings
                        </label>
                    </Box>
                    <div className="mx-2" >
                        <Paper className="p-l-r-15 m-h-625 stocks-paper p-inline-25 ">
                            <Tabs
                                variant="scrollable"
                                allowScrollButtonsMobile={true}
                                className="width-control indicator-control "
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label=""
                            >
                                <Tab
                                    className="tab-s "

                                    label={
                                        <Box display={"inline-flex"} alignItems="center">
                                            <LockIcon className="avatar-12px" />
                                            <label className=" setting-primary-label mouse-event mx-5px ">

                                                CHANGE PASSWORD
                                            </label>
                                        </Box>

                                    }
                                    {...a11yProps(0)}
                                />
                            </Tabs>
                            <Divider />
                            <TabPanel value={value} index={0}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        <ResetPassword />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        <Commission />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        {/* component 3*/}

                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        {/* <EditCommission /> */}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        {/* component 5 */}

                                    </Grid>
                                </Grid>
                            </TabPanel>

                        </Paper>
                    </div>
                </Box>
            </DashboardLayout>
        </>
    );
}

export default Settings;
