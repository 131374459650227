import { Avatar, Box, ListItemText, makeStyles, Table, TableBody, Button, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useEffect, useState } from "react";
import ApprovalButtons from "./ApprovalButtons";
import ColoredSpan from "./ColoredSpan";
import RequestTransactionDetailModal from "./RequestTransactionDetailModal";
import { dayMonthTimeFormat } from "./funtions/dateFormatter"
import { useSelector } from "react-redux";
import { currencyFormatter } from "./funtions/currencyFormatter";
import { funds, updateFundStatus } from "../api/transactions.js"
import { getToken } from "./funtions/getToken.js"
import { setFundPageCount, updateFundsData } from "../redux/actions/action.js"
import { useDispatch } from "react-redux";
import CustomSnackBar from "./CustomSnackBar";
import { fundFailedStatus, fundPendingStatus } from "../config/constraints";
import { useParams } from "react-router-dom";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});


const RequestsTableComponent = ({ hasApproval, hasPrice, hasUser, isUserDetailTable, isTrader, isUserDetailModal, data, request }) => {
    const [open, setOpen] = useState(false);
    const param = useParams()
    const [modalStatus, setModalStatus] = useState("approved");
    const [modalType, setModalType] = useState("trader");
    const [modalData, setModalData] = useState({})
    const [tableData, setTableData] = useState([]);
    const [toastObj, setToastObj] = useState({
        open: false,
        snackbarMsg: "",
        type: "success",
        onClose: () => {
            setToastObj((prev) => {
                return {
                    ...prev,
                    open: false
                }
            })
        }
    })
    const token = getToken();
    const componentData = data || [];
    const dispatch = useDispatch();
    let start = useSelector((state) => state.fundsPaginationReducer.start)
    let filters = useSelector((state) => state.filtersReducer)
    const getFunds = async () => {
        let id = null;
        if (window.location.pathname.includes("user-details")) {
            id = param.id
        }
        const payload = {
            start,
            time_from: filters.from,
            time_to: filters.to,
            status: filters.category,
            type: request,
            user_id: id
        }
        Object.keys(payload).forEach(key => {
            if (payload[key] === null) {
                delete payload[key];
            }
        });
        const result = await funds(payload, token);
        if (result.status === 200) {
            if (result.data.is_success) {
                setTableData(result?.data?.data?.fundsArr)
                dispatch(updateFundsData(result?.data?.data?.fundsArr))
                dispatch(setFundPageCount(result?.data?.data?.total_pages))
            }
        }
    }
    const approveAction = (id, user_id) => {
        let newObj = {
            fund_id: id,
            action: "processing",
            user_id: user_id,
        }
        updateStatus(newObj)
    }
    const rejectAction = (id, user_id) => {
        let newObj = {
            fund_id: id,
            action: "rejected",
            user_id: user_id,
        }
        updateStatus(newObj)
    }
    const completeAction = (id, user_id, status) => {
        let newObj = {
            fund_id: id,
            action: status,
            user_id: user_id,
        }
        updateStatus(newObj)
    }
    const updateStatus = async (data) => {
        try {
            const result = await updateFundStatus(data, token);
            if (result.status === 200) {
                if (result.data.is_success) {
                    let copyArray = [...tableData];
                    let action = result.data.data.action;
                    let updatedData = copyArray.map((value) => value._id === result.data.data._id ? { ...value, action } : value);
                    setTableData(updatedData);
                    setToastObj((prev) => {
                        return {
                            ...prev,
                            open: true,
                            snackbarMsg: "Status change successful!",
                            type: "success"
                        }
                    })
                } else {
                    setToastObj((prev) => {
                        return {
                            ...prev,
                            open: true,
                            snackbarMsg: "Status change failed,please retry after few minutes! ",
                            type: "error"
                        }
                    })
                }
            } else {
                setToastObj((prev) => {
                    return {
                        ...prev,
                        open: true,
                        snackbarMsg: "Something went Wrong",
                        type: "error"
                    }
                })
            }
        } catch (error) {
            setToastObj((prev) => {
                return {
                    ...prev,
                    open: true,
                    snackbarMsg: "Something went Wrong",
                    type: "error"
                }
            })
        }

    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    useEffect(() => {
        if (request === "trader") return;
        getFunds();
    }, [start, filters])

    const classes = useStyles()
    return <>
        <CustomSnackBar {...toastObj} />
        {isTrader ? <> {componentData.length < 1 ? <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="300px">
            <Box className="fs-14 fw-500 text-black">
                No Data Available
            </Box>
        </Box> : <TableContainer className={classes.paper} >
            <Table className={classes.table} aria-label="simple table">
                {isUserDetailTable ? null : <TableHead>
                    <TableRow >
                        <TableCell className='fs-12 fw-600 text-grey' >{hasUser ? "USERS" : "DATE/TIME"}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">{hasUser ? "DATE/TIME" : null}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">STATUS</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">{hasPrice ? "AMOUNT" : null}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">{hasApproval ? "APPROVAL" : null}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="right"><Box position="relative" right={35}>ACTION</Box></TableCell>
                    </TableRow>
                </TableHead>}

                <TableBody>
                    {componentData.map((value, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {hasUser ? <Box display={"inline-flex"} alignItems="center" >
                                    <Box marginRight={1}> <Avatar className='avatar-h-3' src={value.user_image ? value.user_image : ""} /></Box>
                                    <ListItemText primaryTypographyProps={{ className: "fw-500 fs-14 text-blue-dark" }} secondaryTypographyProps={{ className: "fw-500 fs-12" }} primary={value.user_details.name} secondary={value.user_details.user_name} />
                                </Box > : <span className='fw-500 fs-12 text-grey' align="left">{value.createdAt ? dayMonthTimeFormat(value.updatedAt) : null}</span>}
                            </TableCell>
                            {isUserDetailTable ? <TableCell className="fs-14 fw-600 text-blue-dark" align="left">
                                $1,050.25
                            </TableCell> : null}
                            <TableCell className='fw-500 fs-12 text-grey' align="left">     {hasUser ? value.createdAt ? dayMonthTimeFormat(value.updatedAt) : null : null}</TableCell>
                            <TableCell align="left">
                                {value.status === "active" ? <ColoredSpan fontSize={12} color={"success"} background={"green-light"} >Approved</ColoredSpan> : null}
                                {value.status !== "rejected" && value.status !== "active" ? <ColoredSpan fontSize={12} color={"purple"} background={"blue-light"} >{(value.status[0].toUpperCase() + value.status.toLowerCase().slice(1))}</ColoredSpan> : null}
                                {value.status === "rejected" ? <ColoredSpan fontSize={12} color={"error"} background={"error-light"} >Rejected</ColoredSpan> : null}
                            </TableCell>
                            <TableCell className="fs-14 fw-600 text-blue-dark" align="left">
                                {hasPrice ? "$1,050.25" : null}
                            </TableCell>
                            <TableCell align="left">
                                {hasApproval && value.status === "processing" ? < ApprovalButtons /> : null}
                            </TableCell>
                            <TableCell className='fs-14 fw-600 text-blue ' align="right" ><span className='mouse-click' onClick={() => {
                                setModalData(value)
                                setModalStatus(value.status)
                                setModalType(request)
                                handleOpen();
                            }}>View Details</span></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >}


            <RequestTransactionDetailModal open={open} handleClose={handleClose} isTradeModal={isTrader} type={modalType} status={modalStatus} isUserDetailModal={isUserDetailModal} data={modalData} />
        </>
            : <>{tableData.length < 1 ? <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="300px">
                <Box className="fs-14 fw-500 text-black">
                    No Data Available
                </Box>
            </Box> : <TableContainer className={classes.paper} >
                <Table className={classes.table} aria-label="simple table">
                    {isUserDetailTable ? null : <TableHead>
                        <TableRow >
                            <TableCell className='fs-12 fw-600 text-grey' >{hasUser ? "USERS" : "DATE/TIME"}</TableCell>
                            <TableCell className='fs-12 fw-600 text-grey' align="left">{hasUser ? "DATE/TIME" : null}</TableCell>
                            <TableCell className='fs-12 fw-600 text-grey' align="left">STATUS</TableCell>
                            {isTrader ? null : <TableCell className='fs-12 fw-600 text-grey' align="left">AlPACA STATUS</TableCell>}
                            <TableCell className='fs-12 fw-600 text-grey' align="left">{hasPrice ? "AMOUNT" : null}</TableCell>
                            <TableCell className='fs-12 fw-600 text-grey' align="left">{hasApproval ? "APPROVAL" : null}</TableCell>
                            <TableCell className='fs-12 fw-600 text-grey' align="right"><Box position="relative" right={35}>ACTION</Box></TableCell>
                        </TableRow>
                    </TableHead>}

                    <TableBody>
                        {tableData.map((value, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {hasUser ? <Box display={"inline-flex"} alignItems="center" >
                                        <Box marginRight={1}> <Avatar className='avatar-h-3' src={value.users_details.profile_image ? value.users_details.profile_image : ""} /></Box>
                                        <ListItemText primaryTypographyProps={{ className: "fw-500 fs-14 text-blue-dark" }} secondaryTypographyProps={{ className: "fw-500 fs-12" }} primary={value.users_details.name} secondary={value.users_details.user_name} />
                                    </Box > : <span className='fw-500 fs-12 text-grey' align="left">{value.createdAt ? dayMonthTimeFormat(value.updatedAt) : null}</span>}
                                </TableCell>
                                {isUserDetailTable ? <TableCell className="fs-14 fw-600 text-blue-dark" align="left">
                                    {value.amount ? `$${currencyFormatter(value.amount)}` : null}
                                </TableCell> : null}
                                <TableCell className='fw-500 fs-12 text-grey' align="left">     {hasUser ? value.createdAt ? dayMonthTimeFormat(value.updatedAt) : null : null}</TableCell>
                                <TableCell align="left">
                                    {value.action === "completed" ? <ColoredSpan fontSize={12} color={"success"} background={"green-light"} >Approved</ColoredSpan> : null}
                                    {/* {value.journal_status === "correct" && value.action !== "completed" ? <ColoredSpan fontSize={12} color={"purple"} background={"blue-light"} >Processing</ColoredSpan> : null} */}
                                    {(fundPendingStatus.includes(value.action.toLowerCase())) || (value.journal_status === "correct" && value.action !== "completed") ? <ColoredSpan fontSize={12} color={"purple"} background={"blue-light"} >Processing</ColoredSpan> : null}
                                    {fundFailedStatus.includes(value.action.toLowerCase()) ? <ColoredSpan fontSize={12} color={"error"} background={"error-light"} >Rejected</ColoredSpan> : null}
                                </TableCell>
                                <TableCell align="left" className='fw-600 fs-14 text-blue-dark'>
                                    {value.journal_status ? (value.journal_status[0].toUpperCase() + value.journal_status.slice(1)) : null}
                                </TableCell>
                                <TableCell className="fs-14 fw-600 text-blue-dark" align="left">
                                    {hasPrice ? value.amount ? `$${currencyFormatter(value.amount)}` : null : null}
                                </TableCell>
                                <TableCell align="left">
                                    {hasApproval && (value.action === "submitted") ? < ApprovalButtons onApprove={() => approveAction(value._id, value.user_id)} onReject={() => rejectAction(value._id, value.user_id)} /> : null}
                                    {(hasApproval && request === "withdraw") && (value.journal_status === "executed" && value.action !== "completed") ? <Button variant="outlined" color="secondary" onClick={() => completeAction(value._id, value.user_id, "completed")}>Complete </Button> : null}
                                    {(hasApproval && request === "withdraw") && (value.journal_status === "refused" || value.journal_status === "rejected") ? <Button variant="outlined" color="secondary" onClick={() => completeAction(value._id, value.user_id, "retrying")}>Retry </Button> : null}


                                </TableCell>
                                <TableCell className='fs-14 fw-600 text-blue ' align="right" ><span className='mouse-click' onClick={() => {
                                    setModalData(value)
                                    setModalStatus(value.status)
                                    setModalType(value.type)
                                    handleOpen();
                                }}>View Details</span></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
            }

                <RequestTransactionDetailModal open={open} handleClose={handleClose} isTradeModal={isTrader} type={modalType} status={modalStatus} isUserDetailModal={isUserDetailModal} data={modalData} onApprove={() => approveAction(modalData._id, modalData.user_id)} onReject={() => rejectAction(modalData._id, modalData.user_id)} />

            </>}
    </>

};

export default RequestsTableComponent;


