import { Box } from '@material-ui/core'
import React from 'react'
import CategoryFilter from './Filters/CategoryFilter'
import SortByFilter from './Filters/SortByFilter'
import RequestsTableComponent from './RequestsTableComponent'

const FundWithdrawRequest = () => {

    return <>
        <Box display="inline-flex" width="100%" justifyContent="flex-end" alignItems="center" >
            <Box marginRight="10px">
                <CategoryFilter trader />
            </Box>
            <Box >
                <SortByFilter funds />
            </Box>
        </Box>
        <RequestsTableComponent hasUser hasApproval hasPrice request="withdraw" />

    </>
}

export default FundWithdrawRequest