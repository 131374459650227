import { Box, Button, CircularProgress, InputLabel, OutlinedInput, IconButton, Typography, Snackbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminSignIn } from '../api/authentification';
import SigninLayout from './layout/SigninLayout';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch } from 'react-redux';
import { setAdminEmail } from '../redux/actions/action';
import Cookies from 'universal-cookie/es6';
import { validateToken } from '../components/funtions/validateToken';

const Signin = () => {
    const navigate = useNavigate()
    const cookies = new Cookies()
    const [authObj, setAuthObj] = useState({
        email: null,
        password: null,
        error: false,
        errorMsg: null
    })
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [hidden, setHidden] = useState(true)
    const [snackState, setSnackState] = useState({
        open: false,
        msg: null
    })

    const handleSignin = (event) => {
        if (event.target.name === "email") {
            setAuthObj((prev) => {
                return {
                    ...prev,
                    email: event.target.value
                }
            })

        } else if (event.target.name === "password") {
            setAuthObj((prev) => {
                return {
                    ...prev,
                    password: event.target.value
                }
            })
        }
        setAuthObj((prev) => {
            return {
                ...prev,
                error: false,
                errorMsg: null
            }
        })

    }
    const handleClose = () => {
        setSnackState((prev) => {
            return {
                ...prev,
                open: false,
                msg: null
            }
        })
    };
    const handlePasswordIcon = () => {
        setHidden(!hidden)
    }
    const handleOnClick = async () => {
        try {
            setIsLoading(true)
            const response = await adminSignIn(authObj);
            if (response.status === 200) {
                if (response.data.is_success) {
                    cookies.set('token', response.data.data.accessToken, { path: '/' })
                    dispatch(setAdminEmail(authObj.email))
                    // navigate('/sign-in/enter-code')
                    navigate('/users')
                } else {
                    setAuthObj((prev) => {
                        return {
                            ...prev,
                            error: true,
                            errorMsg: "Email or Password is incorrect"
                        }
                    })
                }
            } else {
                setSnackState((prev) => {
                    return {
                        ...prev,
                        open: true,
                        msg: "Something went wrong"
                    }
                })
            }
        } catch {
            setSnackState((prev) => {
                return {
                    ...prev,
                    open: true,
                    msg: "Something went wrong"
                }
            })
        }
        setIsLoading(false)
    }
    useEffect(() => {
        let token = validateToken()
        if (token === true) {
            navigate('/users')
        }
    }, [])

    return <SigninLayout>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackState.open}
            onClose={handleClose}
            message={snackState.msg}
        />
        <Box>
            <Box display={"flex"} flexDirection="column" width={"100%"} className='my-1'>
                <span className='fs-32 text-blue fw-600'>Sign in to Axehedge Admin </span>
                <span className="fs-16 my-5px fw-300 text-grey"> Enter your details below</span>
            </Box>
            <Box width={"100%"} className='my-1'>
                <InputLabel className="my-5px fs-12 fw-600 text-grey">USERNAME</InputLabel>
                <OutlinedInput autoComplete='off' error={authObj.error} name="email" className=' h-40' color='secondary' fullWidth onChange={handleSignin} />
            </Box>
            <Box width={"100%"} className='my-1 '>
                <InputLabel className="my-5px fs-12 fw-600 text-grey ">PASSWORD</InputLabel>
                <OutlinedInput type={hidden ? "password" : "text"} endAdornment={<IconButton className='p-0' onClick={handlePasswordIcon}>{hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>} autoComplete='off' error={authObj.error} name="password" className=' h-40' color='secondary' fullWidth onChange={handleSignin} />
                <Typography className='my-5px fs-12 fw-400 text-error'>{authObj.error && authObj.errorMsg}</Typography>
            </Box>
            <Box className='my-2' width={"100%"}><Button className=' h-40' disabled={authObj.email === null || authObj.email === "" || authObj.password === null || authObj.password === "" ? true : false} fullWidth color="secondary" variant="contained" onClick={handleOnClick} >{isLoading ? <CircularProgress className="avatar-h-1" /> : "Login"}</Button></Box>

        </Box>

    </SigninLayout >;
};

export default Signin;
