import { Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, OutlinedInput } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import { resetPassword } from '../api/authentification';
import { getToken } from './funtions/getToken';
import CustomSnackBar from './CustomSnackBar';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const navigate = useNavigate()
    const token = getToken();
    const email = useSelector((state) => state.adminData.email)
    const [snackState, setSnackState] = useState({
        msg: "",
        toggle: false,
        type: "",

    })
    const [resetPasswordForm, setResetPasswordForm] = useState({
        currentPassword: "",
        newPassword: "",
        retypedPassword: ""
    })
    const [labelState, setLabelState] = useState({
        colorCurrent: "black",
        colorNew: "black",
        colorRetype: "black",
        currentError: false,
        newError: false,
        retypeError: false,
        currentMsg: "",
        newMsg: "",
        retypeMsg: ""


    })
    const [visibility, setVisibility] = useState({
        visibilityCurrent: false,
        visibilityNew: false,
        visibilityRetype: false
    });

    const resetAdminPassword = async () => {
        try {
            const result = await resetPassword(resetPasswordForm.newPassword, resetPasswordForm.currentPassword, token);
            if (result.status === 200) {
                if (result.data.is_success) {
                    setSnackState((prev) => {
                        return {
                            ...prev,
                            toggle: true,
                            msg: "Password Successfully Changed",
                            type: "success"
                        }
                    })
                    // navigate('/dashboard')

                } else {
                    if (result.data.message === "Your old password is wrong") {
                        setLabelState((prev) => {
                            return {
                                ...prev,
                                currentError: true,
                                currentMsg: result.data.message
                            }
                        })
                    } else {

                        setSnackState((prev) => {
                            return {
                                ...prev,
                                toggle: true,
                                msg: result.data.message,
                                type: "error"
                            }
                        })
                    }

                }
            } else {
                setSnackState((prev) => {
                    return {
                        ...prev,
                        toggle: true,
                        msg: "something went wrong",
                        type: "error"
                    }
                })
            }
        } catch {
            setSnackState((prev) => {
                return {
                    ...prev,
                    toggle: true,
                    msg: "something went wrong",
                    type: "error"
                }
            })
        }
    }
    const toggleVisiblity = (event) => {
        if (event.currentTarget.id === "current") {
            setVisibility((prev) => {
                return {
                    ...prev,
                    visibilityCurrent: !prev.visibilityCurrent,
                }
            })
        } else if (event.currentTarget.id === "new") {
            setVisibility((prev) => {
                return {
                    ...prev,
                    visibilityNew: !prev.visibilityNew,
                }
            })
        } else if (event.currentTarget.id === "retype") {
            setVisibility((prev) => {
                return {
                    ...prev,
                    visibilityRetype: !prev.visibilityRetype,
                }
            })
        }

    }
    const resetErrorState = () => {
        setLabelState((prev) => {
            return {
                ...prev,
                currentError: false,
                newError: false,
                retypeError: false,
                currentMsg: "",
                newMsg: "",
                retypeMsg: ""


            }
        })
    }
    const resetPasswordSubmitHandler = () => {
        if (resetPasswordForm.currentPassword === "" || resetPasswordForm.newPassword === "") {
            setLabelState((prev) => {
                return {
                    ...prev,
                    currentError: true,
                    newError: true,
                    retypeError: true,
                    retypeMsg: "Fields Cannot be Empty"
                }
            })
            return
        }
        if (resetPasswordForm.newPassword.length < 8) {
            setLabelState((prev) => {
                return {
                    ...prev,
                    newError: true,
                    newMsg: "Password cannot be less than 8 characters"
                }
            })
            return
        }
        if (resetPasswordForm.newPassword !== resetPasswordForm.retypedPassword) {
            setLabelState((prev) => {
                return {
                    ...prev,
                    newError: true,
                    retypeError: true,
                    retypeMsg: "New password and retyped password doesn't match"
                }
            })
            return
        }
        resetAdminPassword()
        setSnackState((prev) => {
            return {
                ...prev,
                toggle: false
            }
        })
    }
    return (

        <Grid item xs={12} md={6} lg={4} >
            <CustomSnackBar open={snackState.toggle} snackbarMsg={snackState.msg} type={snackState.type} setSnackState={setSnackState} />
            <Box my={1}>
                <InputLabel htmlFor='currentPass' className={`fs-15 fw-500 text-${labelState.colorCurrent} m-b-2px`}>Current Password</InputLabel>
                <FormControl fullWidth>
                    <OutlinedInput error={labelState.currentError ? true : false} id="currentPass" aria-describedby='currentPassHelperText' onFocus={() => { setLabelState((prev) => { return { ...prev, colorCurrent: "blue", colorNew: "black", colorRetype: "black" } }) }} fullWidth className='theme-input' type={visibility.visibilityCurrent ? "text" : "password"} endAdornment={<IconButton className='h-w-18' id="current" onClick={toggleVisiblity} >{visibility.visibilityCurrent ? <Visibility className='text-blue' /> : <VisibilityOff className='text-grey-light' />} </IconButton>} onChange={(event) => { resetErrorState(); setResetPasswordForm((prev) => { return { ...prev, currentPassword: event.target.value } }) }} />
                    {labelState.currentError ? <FormHelperText id="currentPassHelperText" className='text-error flex item-center'>{labelState.currentMsg === "" ? null : <ErrorOutlinedIcon className='mx-5px h-w-18' />}{labelState.currentMsg}</FormHelperText> : null}
                </FormControl>
            </Box>
            <Box my={3}>
                <InputLabel htmlFor='newPass' className={`fs-15 fw-500 text-${labelState.colorNew} m-b-2px`}>New Password</InputLabel>
                <FormControl fullWidth>
                    <OutlinedInput error={labelState.newError ? true : false} id="newPass" aria-describedby='newPassHelperText' onFocus={() => { setLabelState((prev) => { return { ...prev, colorCurrent: "black", colorNew: "blue", colorRetype: "black" } }) }} fullWidth className='theme-input' type={visibility.visibilityNew ? "text" : "password"} endAdornment={<IconButton className='h-w-18' id="new" onClick={toggleVisiblity}>{visibility.visibilityNew ? <Visibility className='text-blue' /> : <VisibilityOff className='text-grey-light' />} </IconButton>} onChange={(event) => { resetErrorState(); setResetPasswordForm((prev) => { return { ...prev, newPassword: event.target.value } }) }} />
                    {labelState.newError ? <FormHelperText id="newPassHelperText" className='text-error flex item-center'>{labelState.newMsg === "" ? null : <ErrorOutlinedIcon className='mx-5px h-w-18' />}{labelState.newMsg}</FormHelperText> : null}
                </FormControl>
            </Box>
            <Box my={1}>
                <InputLabel htmlFor='retypePass' className={`fs-15 fw-500 text-${labelState.colorRetype} m-b-2px`}>Re-type Password</InputLabel>
                <FormControl fullWidth>
                    <OutlinedInput error={labelState.retypeError ? true : false} id="retypePass" aria-describedby='retypePassHelperText' onFocus={() => { setLabelState((prev) => { return { ...prev, colorCurrent: "black", colorNew: "black", colorRetype: "blue" } }) }} fullWidth className='theme-input' type={visibility.visibilityRetype ? "text " : "password"} endAdornment={<IconButton className='h-w-18' id="retype" onClick={toggleVisiblity}>{visibility.visibilityRetype ? <Visibility className='text-blue' /> : <VisibilityOff className='text-grey-light' />} </IconButton>} onChange={(event) => { resetErrorState(); setResetPasswordForm((prev) => { return { ...prev, retypedPassword: event.target.value } }) }} />
                    {labelState.retypeError ? <FormHelperText id="retypePassHelperText" className='text-error flex item-center'>{labelState.retypeMsg === "" ? null : <ErrorOutlinedIcon className='mx-5px h-w-18' />}{labelState.retypeMsg}</FormHelperText> : null}
                </FormControl>

            </Box>
            <Box my={5}>
                <Button className='bg-color-success text-white min-width-120' onClick={resetPasswordSubmitHandler} >Update</Button>
            </Box>

        </Grid >

    )

}

export default ResetPassword