import { Box } from '@material-ui/core'
import React from 'react'
import approveIcon from "../assets/images/approveIcon.svg"
import rejectIcon from "../assets/images/RejectIcon.svg"


const ApprovalButtons = ({ hasText, onApprove, onReject }) => {
    return (
        <Box display="inline-flex">
            <Box minWidth={hasText ? "170px" : "0"} height="40px" className='approve-button-reject animate' display="inline-flex" justifyContent="center" alignItems="center" onClick={onReject ? onReject : null} >
                <img src={rejectIcon} alt="rejected" />
                {hasText ? <span className='fs-16 fw-500 text-error mx-5px'>Reject</span> : null}
            </Box>
            <Box minWidth={hasText ? "170px" : "0"} height="40px" className="approve-button animate" marginLeft="11px" display="inline-flex" justifyContent="center" alignItems="center" onClick={onApprove ? onApprove : null}   >
                <img src={approveIcon} alt="approval" />
                {hasText ? <span className='fs-16 fw-500 text-success mx-5px'>Approve</span> : null}
            </Box>
        </Box >
    )
}

export default ApprovalButtons