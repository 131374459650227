const initialState = {
    mode: "paper",
    counter:0
  };
  
  const modeSwitch = (state = initialState, action) => {
    if (action.type === "changeMode") {
      state = {
        ...state,
        mode: action.payload,
      };
      return state;
    }else  if (action.type === "checkModeCounter") {
      state = {
        ...state,
        counter: action.payload,
      };
      return state;
    }  else {
      return state;
    }
  };
  
  export default modeSwitch;