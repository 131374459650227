import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, Divider, Grid, Paper, Snackbar } from "@material-ui/core";
import DashboardLayout from "./layout/DashboardLayout";
import withdrawIcon from "../assets/images/withdrawalIcon.svg";
import InactivewithdrawIcon from "../assets/images/withdrawalIconGrey.svg";
import fundDepositIcon from "../assets/images/fundDepositIcon.svg";
import InactivefundDepositIcon from "../assets/images/fundDepositIconGrey.svg";
import ProductFundDepositRequest from "../components/ProductFundDepositRequest";
import ProductFundWithdrawalRequest from "../components/ProductFundWithdrawalRequest";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <div p={7}>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function ProductFundsRequests() {
    const [tabIndex, setValue] = useState(0);
    const [snackBar, setSnackBar] = useState({
        value: "",
        status: false,
    }); 
    const handleChange = (event, newValue) => {
        setValue(newValue); 
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackBar.status}
                onClose={() =>
                    setSnackBar((prev) => {
                        return { ...prev, status: false };
                    })
                }
                message={snackBar.value}
            />
            <DashboardLayout>
                <Box width="100%" mx="26px" >
                    <Box marginY={2} className="inline-flex space-btw item-center">
                        <label className="heading-watchlist">
                        ProductFunds Requests
                        </label>
                    </Box>
                    <div >
                        <Paper className="p-l-r-15 m-h-625 stocks-paper p-inline-25 ">
                            <Tabs
                                variant="scrollable"
                                allowScrollButtonsMobile={true}
                                className="width-control indicator-control "
                                value={tabIndex}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label=""
                            > 
                                <Tab 
                                    className="tab-s"
                                    label={
                                        <Box display="inline-flex" alignItems="center">
                                            {tabIndex === 0 ? <img src={fundDepositIcon} alt="fundDepositIcon" /> : <img src={InactivefundDepositIcon} alt="fundDepositIcon" />}
                                            <Box className="fs-12 fw-600" marginLeft={1}>
                                                FUND DEPOSIT REQUEST
                                            </Box>
                                        </Box>
                                    }
                                    {...a11yProps(0)}
                                />
                                <Tab 
                                    className="tab-s tab-margin"
                                    label={
                                        <Box display="inline-flex" alignItems="center">
                                            {tabIndex === 1 ? <img src={withdrawIcon} alt="withdraw Icon" /> : <img src={InactivewithdrawIcon} alt="withdraw Icon" />}
                                            <Box className="fs-12 fw-600" marginLeft={1}>
                                                WITHDRAW REQUEST
                                            </Box>
                                        </Box>
                                    }
                                    {...a11yProps(1)}
                                />

                            </Tabs>
                            <Divider />
                         
                            <TabPanel value={tabIndex} index={0}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        <ProductFundDepositRequest />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        <ProductFundWithdrawalRequest />
                                    </Grid>
                                </Grid>
                            </TabPanel>


                        </Paper>
                    </div>
                </Box>
                {/* <Box mx="26px" width="100%" display="inline-flex" justifyContent="flex-end" alignItems="center" >
                    <Pagination
                        className="text-blue my-5px flex item-center flex-end"
                        color="secondary"
                        showFirstButton={true}
                        showLastButton={true}
                        hideNextButton={false}
                        hidePrevButton={false}
                        count={totalPages}
                        onChange={(e, index) => {
                            let newindex = index - 1;
                            dispatch(setFundPageStart(newindex));
                        }}

                    />
                </Box> */}
            </DashboardLayout>
        </>
    );
}

export default ProductFundsRequests;
