import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Box, Divider, Grid, Paper, Snackbar } from "@material-ui/core";
import DashboardLayout from "./layout/DashboardLayout";
import withdrawIcon from "../assets/images/withdrawalIcon.svg";
import InactivewithdrawIcon from "../assets/images/withdrawalIconGrey.svg";
import fundDepositIcon from "../assets/images/fundDepositIcon.svg";
import InactivefundDepositIcon from "../assets/images/fundDepositIconGrey.svg";
import requestICon from "../assets/images/tradersRequestIcon.svg"
import InactiverequestICon from "../assets/images/tradersRequestIconGrey.svg"
import { Pagination } from "@material-ui/lab";

import TradersRequests from "../components/TradersRequests";
import FundDepositRequest from "../components/FundDepositRequest";
import FundWithdrawRequest from "../components/FundWithdrawRequest";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, resetFundPagination, setFundPageStart } from "../redux/actions/action";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <div p={7}>{children}</div>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function Requests() {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const [snackBar, setSnackBar] = useState({
        value: "",
        status: false,
    });
    const totalPages = useSelector((state) => state.fundsPaginationReducer.page)
    const handleChange = (event, newValue) => {
        setValue(newValue);
        resetPaginationAndFilters()
    };
    const resetPaginationAndFilters = () => {
        dispatch(resetFilters(0));
        dispatch(resetFundPagination(0))
    }
    useEffect(() => {
        let index = localStorage.getItem("selectedIndex");
        if (index > 0) {
            setValue(2);
        }
        localStorage.setItem("selectedIndex", 0);
    }, []);
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackBar.status}
                onClose={() =>
                    setSnackBar((prev) => {
                        return { ...prev, status: false };
                    })
                }
                message={snackBar.value}
            />
            <DashboardLayout>
                <Box width="100%" mx="26px" >
                    <Box marginY={2} className="inline-flex space-btw item-center">
                        <label className="heading-watchlist">
                            Requests
                        </label>
                    </Box>
                    <div >
                        <Paper className="p-l-r-15 m-h-625 stocks-paper p-inline-25 ">
                            <Tabs
                                variant="scrollable"
                                allowScrollButtonsMobile={true}
                                className="width-control indicator-control "
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label=""
                            >
                                <Tab
                                    onClick={resetPaginationAndFilters}
                                    className="tab-s "
                                    label={
                                        <Box display="inline-flex" alignItems="center">
                                            {value === 0 ? <img src={requestICon} alt="tradersIcon" /> : <img src={InactiverequestICon} alt="tradersIcon" />}
                                            <Box className="fs-12 fw-600" marginLeft={1}>
                                                TRADERS REQUESTS
                                            </Box>
                                        </Box>
                                    }
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    onClick={resetPaginationAndFilters}
                                    className="tab-s tab-margin mx-2"
                                    label={
                                        <Box display="inline-flex" alignItems="center">
                                            {value === 1 ? <img src={fundDepositIcon} alt="fundDepositIcon" /> : <img src={InactivefundDepositIcon} alt="fundDepositIcon" />}
                                            <Box className="fs-12 fw-600" marginLeft={1}>
                                                FUND DEPOSIT REQUEST
                                            </Box>
                                        </Box>
                                    }
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    onClick={resetPaginationAndFilters}
                                    className="tab-s tab-margin"
                                    label={
                                        <Box display="inline-flex" alignItems="center">
                                            {value === 2 ? <img src={withdrawIcon} alt="withdraw Icon" /> : <img src={InactivewithdrawIcon} alt="withdraw Icon" />}
                                            <Box className="fs-12 fw-600" marginLeft={1}>
                                                WITHDRAW REQUEST
                                            </Box>
                                        </Box>
                                    }
                                    {...a11yProps(2)}
                                />

                            </Tabs>
                            <Divider />
                            <TabPanel value={value} index={0}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        <TradersRequests />

                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        <FundDepositRequest />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Grid container spacing={4} className="margin-block-25">
                                    <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
                                        <FundWithdrawRequest />
                                    </Grid>
                                </Grid>
                            </TabPanel>


                        </Paper>
                    </div>
                </Box>
                <Box mx="26px" width="100%" display="inline-flex" justifyContent="flex-end" alignItems="center" >
                    <Pagination
                        className="text-blue my-5px flex item-center flex-end"
                        color="secondary"
                        showFirstButton={true}
                        showLastButton={true}
                        hideNextButton={false}
                        hidePrevButton={false}
                        count={totalPages}
                        onChange={(e, index) => {
                            let newindex = index - 1;
                            dispatch(setFundPageStart(newindex));
                        }}

                    />
                </Box>
            </DashboardLayout>
        </>
    );
}

export default Requests;
