import Api from "./index";

export const newsListing = async (category, start, search, filter, sort, token) => {
  try {
    return await Api.get(
      `/v1/admin/premium-content/list/${category}/${start}`,
      {
        params: {
          search,
          sort,
          filter,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const togglePublish = async (publishedContentPieceIds, unpublishedContentPieceIds, token) => {
    try {
      
      return await Api.patch(
        `/v1/admin/premium-content/publish`,
        {
            publishedContentPieceIds,
            unpublishedContentPieceIds,
        },
        {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        }
      );
    } catch (error) {
      return {
        status: 404,
      };
    }
  };

  export const syncNews = async (token) => {
    try {
      return await Api.post(
        `/v1/admin/premium-content/sync`, null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        return {
          status: 404,
        };
      }
    };