const initialState = {
  currentRule: {},
  addArray: [],
  removeArray: [],
  updateArray: [],
  finalArray: [],
};

const strategy = (state = initialState, action) => {
  if (action.type === "toBeAdded") {
    state = {
      ...state,
      addArray: [...state.addArray, action.payload],
    };
    return state;
  } else if (action.type === "toBeRemoved") {
    state = {
      ...state,
      removeArray: [...state.removeArray, action.payload],
    };
    return state;
  } else if (action.type === "toBeUpdated") {
    state = {
      ...state,
      updateArray: [...state.updateArray, action.payload],
    };
    return state;
  } else if (action.type === "setCurrentRule") {
    state = {
      ...state,
      currentRule: action.payload,
    };
    return state;
  } else if (action.type === "setFinalArray") {
    state = {
      ...state,
      finalArray: action.payload,
    };
    return state;
  } else if (action.type === "clearEditData") {
    state = {
      currentRule: {},
      addArray: [],
      removeArray: [],
      updateArray: [],
      finalArray: [],
    };
    return state;
  } else {
    return state;
  }
};

export default strategy;
