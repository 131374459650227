export const fundFailedStatus = ["canceled", "deleted", "rejected", "refused"];
export const fundPendingStatus = [
  "executed",
  "queued",
  "sent_to_clearing",
  "pending",
  "processing",
  "retrying",
];
export const fundSuccessStatus = ["correct"];
export const axehedgeFundSuccessStatus = ["completed"];
