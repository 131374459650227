import { Avatar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import ColoredSpan from './ColoredSpan'
import slipIcon from "../assets/images/SlipIcon.svg"
import ApprovalButtons from './ApprovalButtons'
import { dayMonthTimeFormat } from './funtions/dateFormatter'
import { currencyFormatter } from './funtions/currencyFormatter'
import CustomSnackBar from './CustomSnackBar'
import { useState } from 'react'


const RequestTransactionDetailModal = ({ open, handleClose, isTradeModal, status, isUserDetailModal, type, data, onApprove, onReject }) => {
    let documentLinkString = data?.document?.length > 0 ? data?.document[0]?.key : null
    const downloadDocument = () => {
        if (documentLinkString) {
            window.location.assign(documentLinkString);
        } else {
            setToastObj((prev) => {
                return {
                    ...prev,
                    open: true,
                    type: "error",
                    snackbarMsg: "No Deposit Slip Found"
                }

            })
        }
    }
    const [toastObj, setToastObj] = useState({
        open: false,
        snackbarMsg: "",
        type: "success",
        onClose: () => {
            setToastObj((prev) => {
                return {
                    ...prev,
                    open: false
                }
            })
        }
    })

    return (
        <>
            <CustomSnackBar {...toastObj} />
            <Dialog
                PaperProps={{ className: "border-radius-15 " }}
                fullWidth={false}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >

                <DialogTitle id="max-width-dialog-title"><Box width="100%" display="inline-flex" alignItems="center" justifyContent="space-between" className='fs-28 fw-700 text-blue-dark'><span>Details</span><IconButton onClick={handleClose}>
                    <Close />
                </IconButton></Box></DialogTitle>
                <Divider />
                <DialogContent className='overflow-scroll-hidden'>
                    {isUserDetailModal ? null : <Box minWidth="380px" width="100%" display="flex" flexDirection={"column"} justifyContent="center" alignItems={"center"} marginY="25px
                " >
                        {isTradeModal ? <Avatar className='avatar-90' src={data.user_image ? data.user_image : ""} alt="" /> : <Avatar className='avatar-90' src={data.users_details ? data.users_details?.profile_image : ""} alt="" />}
                        {isTradeModal ? <Box marginTop="6px" className='fs-16 fw-700 text-blue-dark'>{data?.user_details?.name}</Box> : <Box marginTop="6px" className='fs-16 fw-700 text-blue-dark'>{data?.users_details?.name}</Box>}
                        {isTradeModal ? <Box className='fs-12 fw-500 text-grey'>{data?.user_details?.user_name}</Box> : <Box className='fs-12 fw-500 text-grey'>{data?.users_details?.user_name}</Box>}
                    </Box>}
                    <Box display="flex" minWidth="380px" justifyContent="space-between" width="100%" marginTop="36px">
                        <Box display="flex" flexDirection="column" >
                            <span className='fs-16 fw-400 text-grey'>
                                Request Type
                            </span>
                            <span className='fs-16 fw-700 text-blue-dark'>
                                {isTradeModal ? "Register as Trader" : type.toUpperCase()}
                            </span>
                        </Box>
                        {isTradeModal ? null : <Box display="flex" flexDirection="column" >
                            <span className='fs-16 fw-400 text-grey'>
                                Amount
                            </span>
                            <span className='fs-16 fw-700 text-blue-dark'>
                                {data.amount ? `$${currencyFormatter(data.amount)}` : null}
                            </span>
                        </Box>}
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%" marginTop="26px">
                        <Box display="flex" flexDirection="column" >
                            <span className='fs-16 fw-400 text-grey'>
                                {"Date & Time"}
                            </span>
                            <span className='fs-16 fw-700 text-blue-dark'>
                                {dayMonthTimeFormat(data.createdAt)}
                            </span>
                        </Box>
                        <Box display="flex" flexDirection="column" >
                            <span className='fs-16 fw-400 text-grey'>
                                Status
                            </span>
                            {isTradeModal ? <span >
                                {data.status !== "rejected" && data.status !== "active" ? <ColoredSpan fontSize={12} color="purple" >{data.status ? data.status[0].toUpperCase() + data.status.slice(1) : null}</ColoredSpan> : null}
                                {status === "rejected" ? <ColoredSpan fontSize={12} color="error" background="error-light" >Rejected</ColoredSpan> : null}
                                {data.status === "active" ? <ColoredSpan fontSize={12} color="success" background="green-light" >Approved</ColoredSpan> : null}
                            </span> : <span >
                                {data.action !== "rejected" && data.action !== "completed" ? <ColoredSpan fontSize={12} color="purple" >{data.action ? data.action[0].toUpperCase() + data.action.slice(1) : null}</ColoredSpan> : null}
                                {data.action === "rejected" ? <ColoredSpan fontSize={12} color="error" background="error-light" >Rejected</ColoredSpan> : null}
                                {data.action === "completed" ? <ColoredSpan fontSize={12} color="success" background="green-light" >Approved</ColoredSpan> : null}
                            </span>}
                        </Box>
                    </Box>
                    {type === "withdraw" && !isTradeModal ? <Box display="flex" marginTop="26px" flexDirection="column" >
                        <span className='fs-16 fw-400 text-grey'>
                            Bank Name
                        </span>
                        <span className='fs-16 fw-700 text-blue-dark'>
                            {data.bank.name}
                        </span>
                    </Box> : null}
                    {type === "withdraw" && !isTradeModal ? <Box display="flex" marginTop="11px" flexDirection="column" >
                        <span className='fs-16 fw-400 text-grey'>
                            Bank Account Name
                        </span>
                        <span className='fs-16 fw-700 text-blue-dark'>
                            {data.bank.account_name}
                        </span>
                    </Box> : null}
                    {type === "withdraw" && !isTradeModal ? <Box display="flex" marginTop="11px" flexDirection="column" >
                        <span className='fs-16 fw-400 text-grey'>
                            Bank Account
                        </span>
                        <span className='fs-16 fw-700 text-blue-dark'>
                            {data.bank.account_number}
                        </span>
                    </Box> : null}
                    {status === "rejected" ? <Box display="flex" marginTop="11px" flexDirection="column" >
                        <span className='fs-16 fw-400 text-grey'>
                            Rejection Detail
                        </span>
                        <Box width="100%" border={1} className='bg-color-grey-light1 fs-16 fw-500 text-blue-dark border-color-grey border-radius-5' >
                            <Box margin="13px" width="329px"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Box>
                        </Box>
                    </Box> : null}
                    {isTradeModal || type === "withdraw" ? null : <Box className='fs-16 fw-400 text-grey' width="100%" marginTop="26px">
                        Proof of Payment
                    </Box>}
                    {isTradeModal || type === "withdraw" ? null : <Box marginTop="19px" className='mouse-event border-color-grey animate' display="flex" flexDirection="row" alignItems="center" border={1} onClick={downloadDocument} >
                        <Box margin="6px"  >
                            <img src={slipIcon} alt="slip" />
                        </Box>
                        <Box display="flex" flexDirection="column" >
                            <span className='fs-14 fw-600 text-black'>
                                Deposit Receipt
                            </span>
                            <span className='fs-12 fw-600 text-blue' >
                                Click here to download receipt
                            </span>
                        </Box>

                    </Box>}


                </DialogContent>
                <DialogActions>
                    <Box marginTop={isTradeModal || data.action !== "submitted" ? "5px" : "21px"} marginBottom={isTradeModal || data.action !== "submitted" ? "5px" : "32px"} width="100%" display="flex" justifyContent="center">
                        {isTradeModal || data.action !== "submitted" ? null : <ApprovalButtons hasText onApprove={onApprove} onReject={onReject} />}
                    </Box>
                </DialogActions>
            </Dialog >
        </>

    )
}

export default RequestTransactionDetailModal 