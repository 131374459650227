import { Box, CircularProgress, Container, Grid, Input, ListItemText, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
import { userExcel, usersListing } from '../api/user'
import DashboardLayout from '../pages/layout/DashboardLayout';
import { useSelector, useDispatch } from "react-redux";
import { setUserListData } from '../redux/actions/action';
import { ArrowForwardIos,CheckCircleOutline,HighlightOff, SaveAlt } from "@material-ui/icons";
import ColoredSpan from './ColoredSpan';
import UserSort from "./Filters/UserSort";
import UserStatusFilter from "./Filters/UserStatusFilter";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});
const Users = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [start, setStart] = useState(0);
    const [sort, setSort] = useState("date-last");
    const [status, setStatus] = useState("");
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState("");
    const cookies = new Cookies();
    const token = cookies.get("token");
    const usersList = useSelector((state) => state.users.usersListObj);
    const getAllUsers = async () => {
        try {
            const result = await usersListing(start, filter, sort, status, token);
            if (result.status === 200) {
                if (result.data.is_success) {
                    let userDataArr = result.data.data.item;
                    setPage(result.data.data.total_pages);
                    dispatch(setUserListData(userDataArr))
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const downloadAllClick = async() => {
        const response = await userExcel(filter, sort, status, token )
        const url = response.data.data.url
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "user_list.xlsx"; // Specify the filename for the downloaded file
    
            // Append the link to the document and trigger a click
            document.body.appendChild(link);
            link.click();
    
            // Remove the link and revoke the URL after the download is initiated
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
          });
}

    useEffect(() => {
        getAllUsers()
        // eslint-disable-next-line
    }, [start, filter, sort, status])

    return <DashboardLayout>
        {loading ? <Box display={"flex"} justifyContent="center" alignItems={"center"} width={"100%"} height={"80vh"}> <CircularProgress color='secondary' /></Box> : <Container>
        <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
            <Box className='fs-14 fw-500 text-grey'>Pages / Users </Box>
        </Box>
            <Box display="flex" paddingY={2} fontSize={24} fontWeight={700} color="secondary.dark">
                Users
            </Box>
            <Paper className='px-1 py-1 border-radius-10'>
            <Box display="flex" paddingY={1} fontSize={20} fontWeight={700} color="secondary.dark">
                Users Information
            </Box>
            <Box display={"inline-flex"} width={"100%"} flexDirection="row" justifyContent={'space-between'}>
                <Box display={"inline-flex"} marginY={2} >
                    <Box marginRight={2} boxShadow={1} className="h-32  border-radius-5 bg-color-white"><Input disableUnderline placeholder='Search User' inputProps={{ className: 'bg-color-white px-1 border-radius-5 ' }} endAdornment={<SearchOutlined className='text-grey-light' />} 
                    onChange={(event) => {
                        setFilter(event.target.value);
                        }} />
                    </Box>
                    <Box display="inline-flex"> 
                        <Box marginRight="10px"><UserSort setSort={setSort} /></Box>
                        <Box>
                            <UserStatusFilter setStatus = {setStatus}/>
                        </Box>
                    </Box> 
                </Box>
                <Box display={"inline-flex"} flexDirection="row" alignItems={"center"} justifyContent={"end"} paddingX={3} onClick={downloadAllClick} style={{cursor: 'pointer'}}>
                            <Box paddingX={1}><SaveAlt/></Box>
                            <Box className='fw-500 fs-20 text-blue-dark'>Download all</Box>
                </Box>
            </Box>
            <Grid item xs={12}>

                <Box >
                    <TableContainer className={classes.paper} component={Paper} >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell className='fs-14 fw-600 text-grey' >Name</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">USER ID</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Registered Email</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Registered Number</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="center">Status</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersList.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='fw-500 fs-14 text-grey' align="left">{value.username}</TableCell>
                                        <TableCell className='fw-500 fs-14 text-grey' align="left">{value._id}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.email}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.profile.phone_number ? value.profile.phone_number : '-'}</TableCell>
                                        <TableCell component="th" scope="row">
                                            <Box display={"inline-flex"} alignItems="center" >
                                                <Box marginRight={-3}> {value.status === 'active' ? <ColoredSpan color={"success"} background={"transparent"} fontSize={16}><CheckCircleOutline  fontSize='medium'/></ColoredSpan > : value.status === 'suspended' ? <ColoredSpan color={"error"} background={"transparent"} fontSize={16}><HighlightOff  fontSize='medium'/></ColoredSpan> : ''}</Box>
                                                <Box> {value.status === 'active' ? <ColoredSpan color={"success"} background={"transparent"} fontSize={16}>Active</ColoredSpan > : value.status === 'suspended' ? <ColoredSpan color={"error"} background={"transparent"} fontSize={16}>Suspended</ColoredSpan> : ''}</Box>
                                            </Box >
                                        </TableCell>
                                        {/* <TableCell className='fw-600 fs-14' align="left">{value.status === 'active' ? <ColoredSpan color={"success"} background={"transparent"} fontSize={16}><CheckCircleOutline  fontSize='medium'/>Verified</ColoredSpan > : value.status === 'suspended' ? <ColoredSpan color={"error"} background={"transparent"} fontSize={16}><HighlightOff  fontSize='medium'/>Suspended</ColoredSpan> : ''}</TableCell> */}
                                        <TableCell className='fs-14 fw-600 text-blue ' align="left"><span className='mouse-click' onClick={() => navigate(`user-details/${value._id}`)}>{<ArrowForwardIos fontSize='small'/>}</span></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box display={'flex'} justifyContent="flex-end" width={"100%"} my={3}>
                    {/* <Box className='fs-14 fw-500 text-grey' >Show {usersData} from 120 Users</Box> */}
                    <Box><Pagination
                        className="text-blue "
                        color="secondary"
                        showFirstButton={true}
                        showLastButton={true}
                        hideNextButton={false}
                        hidePrevButton={false}
                        count={page}
                        onChange={(e, index) => {
                            let newindex = index - 1;
                            setStart(newindex);
                        }}

                    /></Box>
                </Box>
            </Grid>
            </Paper>
        </Container>}

    </DashboardLayout>
};

export default Users;
