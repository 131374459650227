import { combineReducers } from "redux";
import adminData from "./adminData";
import technicalStrategy from "./techical.strategy";
import strategy from "./strategy";
import strategyList from "./strategyList";
import users from "./users";
import modeSwitch from "./modeSwitch";
import sidebarSwitch from "./sidebarSwitch";
import paginationReducer from "./pagination";
import filtersReducer from "./filters";
import fundsReducer from "./fundsData";
import fundsPaginationReducer from "./fundsPagination";
import transactions from "./transactions";
import news from "./news";

const root = combineReducers({
  adminData,
  technicalStrategy,
  strategy,
  strategyList,
  transactions,
  users,
  modeSwitch,
  sidebarSwitch,
  paginationReducer,
  filtersReducer,
  fundsReducer,
  fundsPaginationReducer,
  news,
});

export default root;
