import {
    Box,
    Button,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core'
import React, { useState, useRef } from 'react'
import ProductFundsRequestTable from './ProductFundsRequestTable'
import { Pagination } from '@material-ui/lab'
import { getProductFundsBalanceRequestList } from '../api/productFunds'
import { getToken } from './funtions/getToken'
import { useEffect } from 'react'
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const ProductFundDepositRequest = () => {
    const [data, setData] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [currentFilter, setCurrentFilter] = useState("ALL");
    const [totalPages, setTotalPage] = useState(0);
    const [filterOpen, setFilterOpen] = useState(false);
    
    const getData = async () => {
        let id = null;
        if (window.location.pathname.includes("user-details")) {
            //  id = param.id
        }
        const payload = {
            start: currentPage,
            // time_from: filters.from,
            // time_to: filters.to, 
            type: "DEPOSIT",
            user_id: id
        }

        if (currentFilter !== "ALL") {
            payload.status = currentFilter;
        };

        Object.keys(payload).forEach(key => {
            if (payload[key] === null) {
                delete payload[key];
            }
        });
        const token = getToken();
        const result = await getProductFundsBalanceRequestList(payload, token);
        if (result.status === 200) {
            if (result.data.is_success) {
                setData(result?.data?.data?.userBalanceArr)
                setTotalPage(result?.data?.data?.total_pages);
            }
        }
    }

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setFilterOpen(false);
        }
    }
    const handleToggle = () => {
        setFilterOpen((prevOpen) => !prevOpen);
    };

    const anchorRef = useRef(null);
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setFilterOpen(false);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [currentPage, currentFilter]);

    return <>
        <Box display="inline-flex" width="100%" justifyContent="flex-end" alignItems="center" >
            <Box marginRight="10px">
                {/*  <CategoryFilter trader /> */}
            </Box>
            <Box >
                <Box position="relative" className="z-index-high">
                    <Button disableElevation variant="contained" className='fs-12 bg-color-blue-light text-blue h-32' endIcon={filterOpen ? <ExpandLess color='secondary' /> : <ExpandMore color='secondary' />} ref={anchorRef}
                        aria-controls={filterOpen ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle} >{"STATUS: "} { currentFilter}</Button>

                    <Popper
                        open={filterOpen}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                className={""}
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={filterOpen}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem onClick={(event) => {
                                                setCurrentFilter("PENDING");
                                                setFilterOpen(false);
                                            }}> {"Pending"}</MenuItem>
                                            <MenuItem onClick={(event) => {
                                                setCurrentFilter("APPROVED");
                                                setFilterOpen(false);
                                            }}>{"Approved"}</MenuItem>
                                            <MenuItem onClick={(event) => {
                                                setCurrentFilter("REJECTED");
                                                setFilterOpen(false);
                                            }}>{"Rejected"}</MenuItem>
                                            <MenuItem onClick={(event) => {
                                                setCurrentFilter("ALL");
                                                setFilterOpen(false);
                                            }}> {"All"}</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Box>
        </Box>
        <ProductFundsRequestTable hasUser hasApproval hasPrice request="DEPOSIT" data={data} setData={setData} setTotalPage={setTotalPage} getData={getData} />
        <Box mx="26px" width="100%" display="inline-flex" justifyContent="flex-end" alignItems="center" >
            <Pagination
                className="text-blue my-5px flex item-center flex-end"
                color="secondary"
                showFirstButton={true}
                showLastButton={true}
                hideNextButton={false}
                hidePrevButton={false}
                count={totalPages}
                onChange={(e, index) => {
                    let newindex = index - 1;
                    setCurrentPage(newindex);
                }}

            />
        </Box>
    </>
}

export default ProductFundDepositRequest
