import React from 'react';
import { Box, Grid, Paper, Hidden } from '@material-ui/core';
import logo from "../../assets/images/logo-2.svg"

const SigninLayout = (props) => {
  return <Box display={"inline-flex"} height={"100vh"} width={"100%"}>
    <Hidden xsDown>
      <Grid item sm={5} md={4} className={"bg-color-blue px-2 py-2"}>
        <Box>
          <img src={logo} alt="logo" />
          <Box>
            <p className='fs-32 text-white fw-500'>Manage your capital like an
              automated investment firm
              at a fraction of the cost
            </p>
            <p className='fs-24 text-white fw-400'>
              A solution for everyone
            </p>
          </Box>
        </Box>
      </Grid>
    </Hidden>
    <Grid item xs={12} md={9}>
      <Box flexGrow={1} height={"90vh"} display="flex" width={"100%"} alignItems={"center"} justifyContent={"center"}>
        <Grid xs={9}>
          <Paper elevation={0}>
            <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
              {props.children}
            </Box>
          </Paper>
        </Grid>
      </Box>
    </Grid>
  </Box>;
};

export default SigninLayout;
