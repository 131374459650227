import date from "date-and-time";
export const dayMonthTimeFormat = (data) => {
  const toFormat = new Date(data);
  let pattern = date.compile("DD MMM YY HH:mm:ss");
  let formattedDate = date.format(toFormat, pattern);
  return formattedDate;
};

export const dayMonthFormat = (data) => {
  const toFormat = new Date(data);
  let pattern = date.compile("DD MMM");
  let formattedDate = date.format(toFormat, pattern);
  return formattedDate;
};


export const monthDayFormat = (data) => {
  const toFormat = new Date(data);
  let pattern = date.compile("MMM DD, YYYY");
  let formattedDate = date.format(toFormat, pattern);
  return formattedDate;
};

// you can get any date by passing how many days to subtract from current date
// "day" is no days to subtract
// 2nd prop is isISO , on passing it we will get ISO formatted Date String 
export const getAnyOldDate = (day,isIso,isShort) => {
  var today = new Date();
  var priorDate = new Date(new Date().setDate(today.getDate() - day));
  if(isIso){
    return priorDate.toISOString()
  }else 
  if(isShort){
    let date = priorDate.toISOString();
    let newDate = date.slice(0,10);
    return newDate
  }
  return priorDate;
};
