const initialState = {
    category: null,
    categoryLabel:"STATUS: ALL",
    to:null,
    from: null,
    timeLabel: "ALL"
  };
  
  const filtersReducer = (state = initialState, action) => {
    if (action.type === "setFromFilters") {
      state = {
        ...state,
        from: action.payload,
      };
      return state;
    } else if (action.type === "setCategoryFilter") {
        state = {
          ...state,
          category: action.payload,
        };
        return state;
      } else if (action.type === "setTimeLabel") {
        state = {
          ...state,
          timeLabel: action.payload,
        };
        return state;
      } else if (action.type === "setCategoryLabel") {
        state = {
          ...state,
          categoryLabel: action.payload,
        };
        return state;
      }else if (action.type === "setCategory") {
        state = {
          ...state,
          category: action.payload,
        };
        return state;
      } 
      else if (action.type === "setToFilters") {
        state = {
          ...state,
          to: action.payload,
        };
        return state;
      } 
      else if (action.type === "resetFilters") {
        state = {
          category: null,
          categoryLabel:"STATUS: ALL",
          to:null,
          from: null,
          timeLabel: "ALL"
        };
        return state;
      } 
       else return state;
  };
  //setToFilters
  export default filtersReducer;
  