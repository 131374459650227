import Api from "./index";
export const individualTransaction = async (payload,token) => {
    try {
      return await Api.get(`/api/v1/admin/transactions`, {
        params:{
            ...payload,
                  },
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };
  export const traderRequests = async (payload,token) => {
    try {
      return await Api.get(`/api/v1/admin/trader`, {
        params:{
            ...payload,
                  },
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };
  export const funds = async (payload,token) => {
    try {
      return await Api.get(`/api/v1/admin/funds`, {
        params:{
            ...payload,
                  },
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };
  export const updateFundStatus = async (payload,token) => {
    try {
      return await Api.patch(`/api/v1/admin/funds`,{
        ...payload
      }, {
      
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };
  export const transactionListing = async (start, filter, sort, status, token ) => {
    try {
      return await Api.get(`/api/v2/admin/transaction/${start}?search=${filter}&status=${status}&sort=${sort}`,
      {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };
  export const singleUserTransaction = async (id,start, filter, sort, status, token ) => {
    try {
      return await Api.get(`/api/v2/admin/transaction/${id}/${start}?search=${filter}&status=${status}&sort=${sort}`,
      {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };

  export const complianceExcel = async (filter, sort, status, token ) => {
    try {
      return await Api.get(`/api/v2/admin/download/compliance?search=${filter}&status=${status}&sort=${sort}`,
      {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };

  export const transactionsExcel = async (filter, sort, status, token ) => {
    try {
      return await Api.get(`/api/v2/admin/download/transaction?search=${filter}&status=${status}&sort=${sort}`,
      {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };

  export const userTransactionsExcel = async (id, filter, sort, status, token ) => {
    try {
      return await Api.get(`/api/v2/admin/download/transaction/${id}?search=${filter}&status=${status}&sort=${sort}`,
      {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      return {
        status: 404,
      };
    }
  };