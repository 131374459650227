const initialState = {
    id: "",
    name: "",
    stocks: [],
    fee_amount: "",
    type: "technical",
    fee_type: "",
    visibility: "public",
    minimum_value: "",
    divide_percentage: "100",
    invite_users: "",
    description: "",
    philosophy: "",
    webhooks_url: ""
};
const technicalStrategy = (state = initialState, action) => {
    if (action.type === "save-technical-strategy") {
        state = {
            ...state,
            ...action.payload,
        };
        return state;
    } else {
        return state;
    }
};
export default technicalStrategy;