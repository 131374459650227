import React from 'react'

const ColoredSpan = (props) => {

    const initialState = {
        fontSize: props.fontSize || "12",
        color: props.color || "blue",
        background: props.background || "blue-light"
    }
    return (
        <span className={`fs-${initialState.fontSize} text-${initialState.color} bg-color-${initialState.background} fw-600 px-1 py-5px border-radius-5`}>{props.children}</span>
    )
}

export default ColoredSpan