const initialState = {
    newsList: [],
    start: 0,
  };
  
  const news = (state = initialState, action) => {
    if (action.type === "setNewsList") {
      state = {
        ...state,
        newsList: action.payload,
      };
      return state;
    } 
    // else if (action.type === "setComplianceListData") {
    //     state = {
    //       ...state,
    //       complianceListObj: action.payload,
    //     };
    //     return state;
    // } 
    else return state;
  };
  
  export default news;
  