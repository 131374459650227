const initialState = {
  strategyListObj: [],
  start: 0,
};

const strategyList = (state = initialState, action) => {
  if (action.type === "updateStrategyList") {
    state = {
      ...state,
      strategyListObj: action.payload,
    };
    return state;
  } else if (action.type === "updateStrategyStart") {
    state = {
      ...state,
      start: action.payload,
    };
    return state;
  } else {
    return state;
  }
};

export default strategyList;
