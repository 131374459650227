import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Users from "./components/Users";
import Compliance from "./components/Compliance";
import ContentManagement from "./components/ContentManagement.jsx"
import DashboardLayout from "./pages/layout/DashboardLayout";
import store from "../src/redux/store/store";
import Dashboard from "./components/Dashboard";
import UserDetails from "./pages/UserDetails";
import TradesDetails from "./pages/TradesDetails.jsx";
import ComplianceDetails from "./pages/ComplianceDetails";
import News from "./components/News.jsx"
import Signin from "./pages/Signin";
import Settings from "./pages/Settings";
import Requests from "./pages/Requests";
import ProductFundsRequests from "./pages/ProductFundsRequests.jsx"
import Trades from "./pages/Trades";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Signin />} />
          <Route exact path="/sign-in" element={<Signin />} />
          <Route exact path="/users" element={<Users />} />
          <Route
            exact
            path="/users/user-details/:id"
            element={<UserDetails />}
          />
          <Route
            exact
            path="/compliance/user-details/:id"
            element={<ComplianceDetails />}
          />
          <Route
            exact
            path="/trades/user-details/:id"
            element={<TradesDetails />}
          />
          <Route exact path="/sign-in" element={<Signin />} />
          <Route exact path="/compliance" element={<Compliance />} />
          <Route exact path="/content-management" element={<ContentManagement />} />
          <Route exact path="/content-management/:category" element={<News />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/requests" element={<Requests />} />
          <Route exact path="/productFundsRequests" element={<ProductFundsRequests />} />
          <Route exact path="/trades" element={<Trades />} />
          <Route path="*" element={<DashboardLayout />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
