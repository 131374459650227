import Api from "./index";
export const totalUserCount = async (date, token) => {
  try {
    return await Api.get(`/api/v1/admin/total/count`, {
      params: {
        date
      },
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};


export const GraphData = async (token) => {
  try {
    return await Api.get(`/api/v1/admin/graph/strategy?subscribe_mode=paper`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const totalSuccessfulTradesCount = async (date, token) => {
  try {
    return await Api.get(`/api/v1/admin/graph/funds`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const fundsGraphData = async (payload, token) => {
  try {
    return await Api.get(`api/v1/admin/graph/transaction`, {
      params: {
        ...payload,
      },
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const stocksWithStats = async (payload, token) => {
  try {
    return await Api.get(`api/v1/admin/individuals/stocks`, {
      params: {
        ...payload,
      },
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

