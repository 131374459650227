import { Box, CircularProgress, Container, Grid, Input, Button, Checkbox, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie/es6';
import DashboardLayout from '../pages/layout/DashboardLayout';
import { useSelector, useDispatch } from "react-redux";
import { ArrowForwardIos } from "@material-ui/icons";
import { newsListing, syncNews, togglePublish } from '../api/news';
import { setNewsList } from '../redux/actions/action';
import ColoredSpan from './ColoredSpan';
import NewsStatusFilter from './Filters/NewsFilter';
import NewsSort from './Filters/NewsSort';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});
const DailyWeeklyNews = () => {

    const params = useParams()

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSync = async() => {
        await syncNews(token)
        window.location.reload();
    };

    const handlePublish = () => {
        togglePublish(selectedItems,[],token)
        setSelectedItems([]);
        window.location.reload();
    };

    const handleUnpublish = () => {
        togglePublish([],selectedItems,token)
        setSelectedItems([]);
        window.location.reload();
    };

    const handleSelectAll = (event) => {
        const selected = event.target.checked;
        setSelectAll(selected);
        const newSelectedItems = selected ? newsList.map(news => news.contentPieceId) : [];
        setSelectedItems(newSelectedItems);
    };

    const handleSelectSingle = (event, contentPieceId) => {
            const selectedIndex = selectedItems.indexOf(contentPieceId);
                let newSelectedItems = [];

                if (selectedIndex === -1 && selectedItems.length < 10) {
                    newSelectedItems = [...selectedItems, contentPieceId];
                } else {
                    newSelectedItems = selectedItems.filter(id => id !== contentPieceId);
                }

            setSelectedItems(newSelectedItems);
            setSelectAll(newSelectedItems.length === newsList.length);
    };

    const formatDateTime = (dateTimeString) => {
        const dateObject = new Date(dateTimeString);

        // Format date using toLocaleString and DateTimeFormat options
        const formattedDateString = dateObject.toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Singapore',
        });
    
        // Format time and UTC information
        const formattedTimeString = dateObject.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'Asia/Singapore',
        });
    
        // Combine date and time with UTC information
        const finalFormattedDateTime = (
            <span>
              {formattedDateString}
              <br />
              {formattedTimeString} (UTC +8 MY)
            </span>
          );    
        return finalFormattedDateTime;
      };

    const classes = useStyles();
    const dispatch = useDispatch()
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(true);
    const [start, setStart] = useState(0);
    const [category, setCategory] = useState("");
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("writtenDate: dsc");
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState("");
    const cookies = new Cookies();
    const token = cookies.get("token");
    const newsList = useSelector((state) => state.news.newsList);
    const navigate = useNavigate()

    const getAllNews = async () => {
        try {
            if(category){
                setSelectAll(false)
            const result = await newsListing(category, start, search, filter, sort, token);
            console.log(result.data.data)
            if (result.data.status === 200) {
                if (result.data.is_success) {
                    let newsDataArray = result.data.data.item;
                    setPage(result.data.data.totalPages);
                    dispatch(setNewsList(newsDataArray))
                }
            }
        }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        switch (params.category){
            case 'daily-weekly-news' :
                setTitle('Daily & Weekly News')
                setCategory('NEWS')
                break;
            case 'analyst-insights' :
                setTitle('Analyst Insights')
                setCategory('INSIGHT')
                break;
            case 'quick-takes' :
                setTitle('Quick Takes')
                setCategory('QUICK_TAKE')
                break;
            case 'educational-guides' :
                setTitle('Educational Guides')
                setCategory('GUIDE')
                break;
            default: 
                navigate('/content-management/')
        }
    }, [params])


    useEffect(() => {
        getAllNews()
    }, [start, filter, sort, search, category])

    return <DashboardLayout>
        {loading ? <Box display={"flex"} justifyContent="center" alignItems={"center"} width={"100%"} height={"80vh"}> <CircularProgress color='secondary' /></Box> : <Container>
        <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
            <Box className='fs-14 fw-500 text-grey'>Pages / Content Management / {title} </Box>
        </Box>
            <Paper className='px-1 py-1 border-radius-10'>
            <Box display="flex" paddingY={1} fontSize={20} fontWeight={700} color="secondary.dark">
                {title}
            </Box>
            <Box display={"inline-flex"} width={"100%"} flexDirection="row" justifyContent={'space-between'}>
                <Box display={"inline-flex"} marginY={2} >
                    <Box marginRight={2} boxShadow={1} className="h-32  border-radius-5 bg-color-white"><Input disableUnderline placeholder='Search' inputProps={{ className: 'bg-color-white px-1 border-radius-5 ' }} endAdornment={<SearchOutlined className='text-grey-light' />} 
                    onChange={(event) => {
                        setSearch(event.target.value);
                        }} />
                    </Box>
                    <Box display="inline-flex"> 
                        <Box marginRight="10px"><NewsSort setSort={setSort} /></Box>
                        <Box>
                            <NewsStatusFilter setFilter = {setFilter}/>
                        </Box>
                    </Box> 
                </Box>
                <Box display={"inline-flex"} flexDirection="row" alignItems={"center"} justifyContent={"end"} style={{cursor: 'pointer'}}>
                <Box marginRight={2} width={"200%"}><Button className=' h-40' fullWidth color="secondary" variant="contained" onClick={handleSync}> Sync </Button></Box>
                <Box marginRight={2} width={"200%"}><Button className=' h-40' fullWidth color="secondary" variant="contained" onClick={handlePublish}>Publish</Button></Box>
                <Box width={"200%"}><Button className=' h-40' fullWidth color="secondary" variant="contained" onClick={handleUnpublish}>Unpublish</Button></Box>
                </Box>
            </Box>
            <Grid item xs={12}>

                <Box >
                    <TableContainer className={classes.paper} component={Paper} >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                <TableCell>
                                <Checkbox
                                        checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Title</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="center">Status</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left">Date Added</TableCell>
                                    <TableCell className='fs-14 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newsList.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Checkbox
                                            checked={selectedItems.indexOf(value.contentPieceId) !== -1}
                                            onChange={(event) => handleSelectSingle(event, value.contentPieceId)}/>
                                         </TableCell>
                                        <TableCell className='fw-500 fs-14 text-grey' align="left">{value.title}</TableCell>
                                        <TableCell component="th" scope="row" align="left">
                                        <Box> {value.status === 'published' ? <ColoredSpan color={"success"} background={"transparent"} fontSize={16}>Published</ColoredSpan > : value.status === 'unpublished' ? <ColoredSpan color={"error"} background={"transparent"} fontSize={16}>Unpublished</ColoredSpan> : value.status === 'new' ? <ColoredSpan color={"warning"} background={"transparent"} fontSize={16}>New</ColoredSpan> : '' }</Box>
                                        </TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{ !value.writtenAt || value.writtenAt === 'Invalid date' ? '-' : formatDateTime(value.writtenAt) }</TableCell>
                                        <TableCell className='fs-14 fw-600 text-blue' align="left"><a href={value.canonicalLink} className='mouse-click' target="_blank" rel="noopener noreferrer"><ArrowForwardIos fontSize='small'/></a></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box display={'flex'} justifyContent="flex-end" width={"100%"} my={3}>
                    <Box><Pagination
                        className="text-blue "
                        color="secondary"
                        showFirstButton={true}
                        showLastButton={true}
                        hideNextButton={false}
                        hidePrevButton={false}
                        count={page}
                        onChange={(e, index) => {
                            let newindex = index - 1;
                            setStart(newindex);
                        }}

                    /></Box>
                </Box>
            </Grid>
            </Paper>
        </Container>
        }
    </DashboardLayout>
};

export default DailyWeeklyNews;