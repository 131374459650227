import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import { Hidden } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';
import SidebarDatafrom from '../../components/SidebarData';
import logo from "../../assets/images/nav_logo.svg"
import NavbarMenuItem from '../../components/NavbarMenuItem.jsx';
import TabViewNavMenu from '../../components/TabViewNavMenu';
import ModeSwitch from '../../components/ModeSwitch';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../redux/actions/action';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  minWidth: {
    minWidth: 160
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    minHeight: 240,
  },
  themeBackground: {
    backgroundColor: theme.palette.secondary.main
  },
  themeGrey: {
    color: theme.palette.grey[400]
  }
}));

const DashboardLayout = (props) => {

  const classes = useStyles();
  let drawerState = useSelector((state) => state.sidebarSwitch.toggle);
  const dispatch = useDispatch();


  const handleDrawerOpen = () => {
    dispatch(toggleSidebar(true))
    // setOpen(true);
  };
  const handleDrawerClose = () => {
    dispatch(toggleSidebar(false))

    // setOpen(false);
  };




  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={1} color='primary' position="absolute" className={clsx(classes.appBar, drawerState && classes.appBarShift)}>
        <Box >
          <Hidden smUp>
            <Box display={'flex'} alignItems={'center'} >

              <Box className={classes.title} display="flex" justifyContent={'flex-start'} marginLeft={1}>
                {drawerState === false ? <img src={logo} alt="" onClick={handleDrawerOpen} /> : null}
              </Box>
              <ModeSwitch />

              <Box >
                <TabViewNavMenu />
              </Box>
            </Box>
          </Hidden>

          <Hidden xsDown>
            <Box display={'flex'} alignItems={'center'} marginLeft={2} marginRight={1} marginY={1}   >
              <IconButton
                edge="start"
                color="secondary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, drawerState && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
              <Box >
                <img src={logo} alt="" />
              </Box>
              <Box className={classes.title} display={"flex"} marginX={2} justifyContent={"flex-start"}>
                {/* <Input disableUnderline sx={{ borderRadius: 50 }} startAdornment={<SearchOutlined className={classes.themeGrey} />} placeholder="Search using keyword" /> */}
              </Box>
              <IconButton color="inherit">
                {/* <Badge badgeContent={4} color="error">
                  <NotificationsIcon color="secondary" />
                </Badge> */}
              </IconButton>
              <ModeSwitch />
              <Box display={"flex"} justifyContent={"flex-end"} marginLeft={1}>
                <NavbarMenuItem />
              </Box>
            </Box>
          </Hidden>

        </Box>
      </AppBar>

      <Drawer

        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !drawerState && classes.drawerPaperClose, classes.themeBackground),
        }}
        open={drawerState}
      >
        <Box className={classes.toolbarIcon} fontWeight={700} marginLeft={1}   >
          <Typography variant="h5" color="secondary" noWrap >
            AXEHEDGE
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon color='primary' />
          </IconButton>
        </Box>

        <List><SidebarDatafrom /></List>

        {/* <List></List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className="min-width-540 ">
            {props.children}
          </Grid>
          {/* <Box pt={4}>
            <Copyright />
          </Box> */}
        </Container>
      </main>
    </div>
  );
}

export default DashboardLayout;
