import {
    IconButton,
    DialogTitle,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
  } from "@material-ui/core";
  import { Close, ReportProblemOutlined } from "@material-ui/icons";
  import CustomSnackBar from "./CustomSnackBar";
  import { useState } from "react";
  
  const ErrorModal = ({ open, handleClose, error, setError }) => {

      
      const [toastObj, setToastObj] = useState({
          open: false,
          snackbarMsg: "",
          type: "success",
          onClose: () => {
              setToastObj((prev) => {
                  return {
                      ...prev,
                      open: false,
                    };
                });
            },
        });
  
    return (
      <>
          <CustomSnackBar {...toastObj} />
      
        <Dialog
          PaperProps={{ className: "border-radius-15 " }}
          fullWidth={false}
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent className="overflow-scroll-hidden">
           <Box minWidth ={"350px"} width={"100%"}> 
           <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"} marginX={1}>
                            <Box><ReportProblemOutlined fontSize="large"/></Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} marginLeft={3}>
                                <Box className='fw-600 fs-16 text-blue-dark'>{error}</Box>
                            </Box>
                            </Box>
           </Box>
            
        
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Box width={"100%"} display={"flex"} flexDirection={"column"}alignItems="center"
              justifyContent={"center"}>
            <Box
                marginY={1}
                width={"90%"}
            >
              <Button
                className=" h-40"
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => setError(null)}>OK
              </Button>
            </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default ErrorModal;