import { Avatar, Box, ListItemText, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useState } from "react";
import ApprovalButtons from "./ApprovalButtons";
import ColoredSpan from "./ColoredSpan";
import ProductFundRequestDetailModal from "./ProductFundRequestDetailModal";
import { dayMonthTimeFormat } from "./funtions/dateFormatter"
import { currencyFormatter } from "./funtions/currencyFormatter";
import { approvalBatchUpdate } from "../api/productFunds.js";
import { getToken } from "./funtions/getToken.js"
import CustomSnackBar from "./CustomSnackBar";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});

const ProductFundsRequestTable = ({ hasApproval, hasPrice, hasUser, isUserDetailTable, isTrader, isUserDetailModal, data, setData, setTotalPage, getData, request, currentPage }) => {
    const [open, setOpen] = useState(false);
    const [modalType, setModalType] = useState("trader");
    const [modalData, setModalData] = useState({});
    // const [tableData, setTableData] = useState([]);
    const [toastObj, setToastObj] = useState({
        open: false,
        snackbarMsg: "",
        type: "success",
        onClose: () => {
            setToastObj((prev) => {
                return {
                    ...prev,
                    open: false
                }
            })
        }
    })
    const token = getToken();
    const tableData = data || [];

    const approveAction = (id) => {
        let newObj = {
            transactions: [
                {
                    transaction_id: id,
                    status: "APPROVED",
                    message: ""
                }
            ]
        }
        updateStatus(newObj)
    }

    const rejectAction = (id) => {
        let newObj = {
            transactions: [
                {
                    transaction_id: id,
                    status: "REJECTED",
                    message: ""
                }
            ]
        }
        updateStatus(newObj)
    }

    const updateStatus = async (data) => {
        try {
            const result = await approvalBatchUpdate(data, token);
            if (result.status === 200) {
                if (result.data.is_success) {

                    getData();
                    setToastObj((prev) => {
                        return {
                            ...prev,
                            open: true,
                            snackbarMsg: "Status change successful!",
                            type: "success"
                        }
                    })
                    setOpen(false);
                } else {
                    setToastObj((prev) => {
                        return {
                            ...prev,
                            open: true,
                            snackbarMsg: "Status change failed,please retry after few minutes! ",
                            type: "error"
                        }
                    })
                    setOpen(false);
                }
            } else {
                setToastObj((prev) => {
                    return {
                        ...prev,
                        open: true,
                        snackbarMsg: "Something went Wrong",
                        type: "error"
                    }
                });
            }
        } catch (error) {
            setToastObj((prev) => {
                return {
                    ...prev,
                    open: true,
                    snackbarMsg: "Something went Wrong",
                    type: "error"
                }
            })
        }

    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }


    const classes = useStyles()
    return <>
        <CustomSnackBar {...toastObj} />

        {tableData.length < 1 ? <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="300px">
            <Box className="fs-14 fw-500 text-black">
                No Data Available
            </Box>
        </Box> : <TableContainer className={classes.paper} >
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow >
                        <TableCell className='fs-12 fw-600 text-grey' align="left" >{hasUser ? "USERS" : "DATE/TIME"}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">{hasUser ? "DATE/TIME  (UPDATED)" : null}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">STATUS</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">{hasPrice ? "AMOUNT" : null}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="left">{hasApproval ? "APPROVAL" : null}</TableCell>
                        <TableCell className='fs-12 fw-600 text-grey' align="right"><Box position="relative" right={35}>ACTION</Box></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {tableData.map((value, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                <Box display={"inline-flex"} alignItems="center" >
                                    <Box marginRight={1}> <Avatar className='avatar-h-3' src={value.users_details.profile_image ? value.users_details.profile_image : ""} /></Box>
                                    <ListItemText primaryTypographyProps={{ className: "fw-500 fs-14 text-blue-dark" }} secondaryTypographyProps={{ className: "fw-500 fs-12" }} primary={value.users_details.name} secondary={value.users_details.user_name} />
                                </Box >

                            </TableCell>

                            <TableCell className='fw-500 fs-12 text-grey' align="left">{hasUser ? value.updatedAt ? dayMonthTimeFormat(value.updatedAt) : null : null}</TableCell>

                            <TableCell align="left">
                                {value.status === "PENDING" ? <ColoredSpan fontSize={12} color={"purple"} background={"blue-light"} >Pending</ColoredSpan> : null}
                                {value.status === "APPROVED" ? <ColoredSpan fontSize={12} color={"success"} background={"green-light"} >Approved</ColoredSpan> : null}
                                {value.status === "REJECTED" ? <ColoredSpan fontSize={12} color={"error"} background={"error-light"} >Rejected</ColoredSpan> : null}
                            </TableCell>

                            <TableCell className="fs-14 fw-600 text-blue-dark" align="left">
                                {hasPrice ? value.amount ? `$${currencyFormatter(value.amount)}` : null : null}
                            </TableCell>
                            <TableCell align="left">
                                {hasApproval && (value.status === "PENDING") ? < ApprovalButtons onApprove={() => approveAction(value._id)} onReject={() => rejectAction(value._id)} /> : null}

                            </TableCell>

                            <TableCell className='fs-14 fw-600 text-blue ' align="right" ><span className='mouse-click' onClick={() => {
                                setModalData(value)
                                setModalType(value.type)
                                handleOpen();
                            }}>View Details</span></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
        }

        <ProductFundRequestDetailModal open={open} handleClose={handleClose} type={modalType} isUserDetailModal={isUserDetailModal} data={modalData} onApprove={() => approveAction(modalData._id)} onReject={() => rejectAction(modalData._id)} />


    </>

};

export default ProductFundsRequestTable;


