const initialState = {
  email: "",
  name: null,
};
const adminData = (state = initialState, action) => {
  if (action.type === "setAdminEmail") {
    state = {
      ...state,
      email: action.payload,
    };
    return state;
  } else if (action.type === "setAdminName") {
  
    state = {
      ...state,
      name: action.payload,
    };
    return state;
  } else {
    return state;
  }
};
export default adminData;
