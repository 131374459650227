import { Box, CircularProgress, Container, Grid, Input, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@material-ui/core';
import { SearchOutlined, SaveAlt } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';
import { transactionListing, transactionsExcel } from '../api/transactions';
import DashboardLayout from '../pages/layout/DashboardLayout';
import { useSelector, useDispatch } from "react-redux";
import { setTransactionListData } from '../redux/actions/action';
import { ArrowForwardIos,CheckCircleOutline,HighlightOff,AccessTime } from "@material-ui/icons";
import ColoredSpan from '../components/ColoredSpan';
import TransactionsSort from '../components/Filters/TransactionsSort'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});
const Trades = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [start, setStart] = useState(0);
    const [sort, setSort] = useState('');
    const [status, setStatus] = useState("");
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState("");

    const transactionList = useSelector((state) => state.transactions.transactionsListObj);
    const cookies = new Cookies();
    const token = cookies.get("token");

    const downloadAllClick = async() => {
        const response = await transactionsExcel(filter, sort, status, token )
        const url = response.data.data.url
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = "transactions_list.xlsx"; // Specify the filename for the downloaded file
    
            // Append the link to the document and trigger a click
            document.body.appendChild(link);
            link.click();
    
            // Remove the link and revoke the URL after the download is initiated
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
          });
}
    
    const displayStatus = (status) => {
        switch (status) {
          case 'COMPLETE':
          case 'EXECUTED':
          case 'ACCEPTED':
                return <Box display={"inline-flex"} alignItems="center" >
                        <Box marginRight={-3}> <ColoredSpan color={"success"} background={"transparent"} fontSize={16}><CheckCircleOutline  fontSize='medium'/></ColoredSpan ></Box >
                        <Box><ColoredSpan color={"success"} background={"transparent"} fontSize={16}>Approved</ColoredSpan ></Box >
                    </Box >
          case 'CANCELED':
          case 'EXPIRED' :
          case 'SUSPENDED':
          case 'REJECTED' :
          case 'STOPPED':
            return  <Box display={"inline-flex"} alignItems="center" >
                        <Box marginRight={-3}> <ColoredSpan color={"error"} background={"transparent"} fontSize={16}><HighlightOff  fontSize='medium'/></ColoredSpan ></Box >
                        <Box><ColoredSpan color={"error"} background={"transparent"} fontSize={16}>Denied</ColoredSpan ></Box >
                    </Box >
          case 'QUEUED':
          case 'FILLED':
          case 'REPLACED':
          case 'PENDING_CANCEL':
          case 'PENDING_REPLACE':
          case 'PENDING_NEW':
          case 'PARTIALLY_FILLED':
          case 'APPROVAL_PENDING':
          case 'DONE_FOR_DAY':
          case 'SENT_TO_CLEARING':
          case 'NEW':
            return  <Box display={"inline-flex"} alignItems="center" >
                        <Box marginRight={-3}> <ColoredSpan color={"warning"} background={"transparent"} fontSize={16}><AccessTime  fontSize='medium'/></ColoredSpan ></Box >
                        <Box><ColoredSpan color={"warning"} background={"transparent"} fontSize={16}>Pending</ColoredSpan ></Box >
                    </Box >
          default:
              return<Box className='fw-600 fs-14 text-blue-dark' align="center">{status}</Box>
        }
      };

    const getAllTransactions = async () => {
        try {
            const result = await transactionListing(start, filter, sort, status, token);
            
            if (result.status === 200) {
                if (result.data.is_success) {
                    let transactionDataArr = result.data.data.transaction;
                    setPage(result.data.data.total_pages);
                    dispatch(setTransactionListData(transactionDataArr))
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const formatDateTime = (dateTimeString) => {
        const dateObject = new Date(dateTimeString);

        // Format date using toLocaleString and DateTimeFormat options
        const formattedDateString = dateObject.toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Singapore',
        });
    
        // Format time and UTC information
        const formattedTimeString = dateObject.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'Asia/Singapore',
        });
    
        // Combine date and time with UTC information
        const finalFormattedDateTime = (
            <span>
              {formattedDateString}
              <br />
              {formattedTimeString} (UTC +8 MY)
            </span>
          );    
        return finalFormattedDateTime;
      };

    useEffect(() => {
        getAllTransactions()
        // eslint-disable-next-line
    }, [start, filter, sort, status])

    return <DashboardLayout>
        {loading ? <Box display={"flex"} justifyContent="center" alignItems={"center"} width={"100%"} height={"80vh"}> <CircularProgress color='secondary' /></Box> : <Container>
            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                <Box className='fs-14 fw-500 text-grey'>Pages / Transactions</Box>
            </Box>
            <Box display="flex" paddingY={2} fontSize={24} fontWeight={700} color="secondary.dark">
                Transactions
            </Box>
            <Paper className='px-1 py-1 border-radius-10'>
            <Box display="flex" paddingY={1} fontSize={20} fontWeight={700} color="secondary.dark">
                Transactions Management
            </Box>
            <Box display={"inline-flex"} width={"100%"} flexDirection="row" justifyContent={'space-between'}>
                <Box display={"inline-flex"} marginY={2} >
                    <Box marginRight={2} boxShadow={1} className="h-32  border-radius-5 bg-color-white"><Input disableUnderline placeholder='Search' inputProps={{ className: 'bg-color-white px-1 border-radius-5 ' }} endAdornment={<SearchOutlined className='text-grey-light' />} 
                    onChange={(event) => {
                        setFilter(event.target.value);
                        }} />
                    </Box>
                    <Box display="inline-flex"> <Box marginRight="10px"><TransactionsSort setSort={setSort} /></Box>
                {/* <Box><UserStatusFilter setStatus = {setStatus}/></Box> */}
                    </Box>
                </Box>
                <Box display={"inline-flex"} flexDirection="row" alignItems={"center"} justifyContent={"end"} paddingX={3} onClick={downloadAllClick} style={{cursor: 'pointer'}}>
                            <Box paddingX={1}><SaveAlt/></Box>
                            <Box className='fw-500 fs-20 text-blue-dark'>Download all</Box>
                </Box>
            </Box>
            <Grid item xs={12}>

                <Box >
                    <TableContainer className={classes.paper} component={Paper} >
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell className='fs-12 fw-600 text-grey' >Name</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Order ID</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Amount in (MYR)</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Amount out (MYR)</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Type</TableCell>
                                    {/* Stocks/Funds/Cash */}
                                    <TableCell className='fs-12 fw-600 text-grey' align="center">Status</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left">Request Date</TableCell>
                                    <TableCell className='fs-12 fw-600 text-grey' align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactionList.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell className='fw-500 fs-12 text-grey' align="left">{value.username}</TableCell>
                                        <TableCell className='fw-500 fs-10 text-grey' align="left">{value.orderId}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.amount_in.toFixed(2)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.amount_out.toFixed(2)}</TableCell>
                                        <TableCell className='fw-600 fs-14 text-blue-dark' align="left">{value.type === 'USER BALANCE' ? 'CASH' : value.type}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {displayStatus(value.status)}
                                        </TableCell>
                                        <TableCell className='fw-600 fs-10 text-blue-dark' align="left">{formatDateTime(value.request_date)}</TableCell>
                                        {/* <TableCell className='fw-600 fs-14' align="left">{value.status === 'active' ? <ColoredSpan color={"success"} background={"transparent"} fontSize={16}><CheckCircleOutline  fontSize='medium'/>Verified</ColoredSpan > : value.status === 'suspended' ? <ColoredSpan color={"error"} background={"transparent"} fontSize={16}><HighlightOff  fontSize='medium'/>Suspended</ColoredSpan> : ''}</TableCell> */}
                                        <TableCell className='fs-10 fw-600 text-blue ' align="left"><span className='mouse-click' onClick={() => navigate(`user-details/${value.user_id}`)}>{<ArrowForwardIos fontSize='small'/>}</span></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box display={'flex'} justifyContent="flex-end" width={"100%"} my={3}>
                    {/* <Box className='fs-14 fw-500 text-grey' >Show {usersData} from 120 Users</Box> */}
                    <Box><Pagination
                        className="text-blue "
                        color="secondary"
                        showFirstButton={true}
                        showLastButton={true}
                        hideNextButton={false}
                        hidePrevButton={false}
                        count={page}
                        onChange={(e, index) => {
                            let newindex = index - 1;
                            setStart(newindex);
                        }}
                        />
                        </Box>
                </Box>
                </Grid>
            </Paper>
        </Container>
        }

    </DashboardLayout>
};

export default Trades;