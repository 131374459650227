export const setAdminEmail = (value) => {
  return {
    type: "setAdminEmail",
    payload: value,
  };
};

export const toBeAdded = (value) => {
  return {
    type: "toBeAdded",
    payload: value,
  };
};

export const toBeRemoved = (value) => {
  return {
    type: "toBeRemoved",
    payload: value,
  };
};

export const toBeUpdated = (value) => {
  return {
    type: "toBeUpdated",
    payload: value,
  };
};
export const setCurrentRule = (value) => {
  return {
    type: "setCurrentRule",
    payload: value,
  };
};
export const clearEditData = (value) => {
  return {
    type: "clearEditData",
    payload: value,
  };
};
export const setFinalArray = (value) => {
  return {
    type: "setFinalArray",
    payload: value,
  };
};

export const saveTechnicalStrategy = (value) => {
  return {
    type: "save-technical-strategy",
    payload: value,
  };
};
export const updateStrategyList = (value) => {
  return {
    type: "updateStrategyList",
    payload: value,
  };
};

export const updateStrategyStart = (value) => {
  return {
    type: "updateStrategyStart",
    payload: value,
  };
};

export const setUserListData = (value) => {
  return {
    type: "setUserListData",
    payload: value,
  };
};

export const setTransactionListData = (value) => {
  return {
    type: "setTransactionListData",
    payload: value,
  };
};
export const setUserTransactionList = (value) => {
  return {
    type: "setUserTransactionList",
    payload: value,
  };
};

export const setComplianceListData = (value) => {
  return {
    type: "setComplianceListData",
    payload: value,
  };
};
export const setAdminName = (value) => {
  return {
    type: "setAdminName",
    payload: value,
  };
};
export const changeMode = (value) => {
  return {
    type: "changeMode",
    payload: value,
  };
};
export const checkModeCounter = (value) => {
  return {
    type: "checkModeCounter",
    payload: value,
  };
};
export const toggleSidebar = (value) => {
  return {
    type: "toggleSidebar",
    payload: value,
  };
};
export const setPageStart = (value) => {
  return {
    type: "setPageStart",
    payload: value,
  };
};
export const setPageCount = (value) => {
  return {
    type: "setPageCount",
    payload: value,
  };
};
export const setFromFilters = (value) => {
  return {
    type: "setFromFilters",
    payload: value,
  };
};
export const setToFilters = (value) => {
  return {
    type: "setToFilters",
    payload: value,
  };
};

export const setCategoryFilter = (value) => {
  return {
    type: "setCategoryFilter",
    payload: value,
  };
};
export const setTimeLabel = (value) => {
  return {
    type: "setTimeLabel",
    payload: value,
  };
};
export const setCategoryLabel = (value) => {
  return {
    type: "setCategoryLabel",
    payload: value,
  };
};
export const setCategory = (value) => {
  return {
    type: "setCategory",
    payload: value,
  };
};
export const resetFilters = (value) => {
  return {
    type: "resetFilters",
    payload: value,
  };
};
export const resetPagination = (value) => {
  return {
    type: "resetPagination",
    payload: value,
  };
};
export const repopulate = (value) => {
  return {
    type: "repopulate",
    payload: value,
  };
};
export const updateFundsData = (value) => {
  return {
    type: "updateFundsData",
    payload: value,
  };
};
export const setFundPageStart = (value) => {
  return {
    type: "setFundPageStart",
    payload: value,
  };
};
export const setFundPageCount = (value) => {
  return {
    type: "setFundPageCount",
    payload: value,
  };
};
export const resetFundPagination = (value) => {
  return {
    type: "resetFundPagination",
    payload: value,
  };
};
export const setNewsList = (value) => {
  return {
    type: "setNewsList",
    payload: value,
  };
};
