import {
    IconButton,
    TextField,
    DialogTitle,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
  } from "@material-ui/core";
  import { Close } from "@material-ui/icons";
  import CustomSnackBar from "./CustomSnackBar";
  import { useState } from "react";
  
  const ComplianceModal = ({ open, handleClose, handleCompliance, allow, setReason, reason }) => {
  
    const [toastObj, setToastObj] = useState({
      open: false,
      snackbarMsg: "",
      type: "success",
      onClose: () => {
        setToastObj((prev) => {
          return {
            ...prev,
            open: false,
          };
        });
      },
    });

    const handleChange = (e) => {
      const { value } = e.target;
      setReason(value);
    };
  
    return (
      <>
          <CustomSnackBar {...toastObj} />
      
        <Dialog
          PaperProps={{ className: "border-radius-15 " }}
          fullWidth={false}
          maxWidth={"sm"}
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        ><DialogTitle id="max-width-dialog-title">
          <Box
            width="100%"
            display="inline-flex"
            alignItems="center"
            justifyContent="space-between"
            className="fs-28 fw-700 text-blue-dark"
          >
            <span>Reason to {allow === 'approved' ? 'Approve' : 'Deny'}</span>
            <IconButton size="small" onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
          <DialogContent className="overflow-scroll-hidden">
           <Box minWidth ={"400px"} width={"100%"}> 
           <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"}>
           <Box className='fs-14 fw-600 text-grey' width={"100%"} paddingX={2} sx={{backgroundColor: "whitesmoke",borderRadius:4, minHeight:300}}>
          <TextField
              InputProps={{ disableUnderline: true }}
              fullWidth
              multiline
              maxRows={20}
              margin="normal"
              name="reason"
              placeholder="Reason"
              value={reason}
              onChange={handleChange}
              // style={{ overflowX: 'hidden' }}
            />
            </Box>
            </Box>
            </Box>
            
        
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Box width={"100%"} display={"flex"} flexDirection={"column"}alignItems="center"
              justifyContent={"center"}>
            <Box
                marginY={1}
                width={"90%"}
            >
              <Button
                className=" h-40"
                fullWidth
                color="secondary"
                variant="contained"
                onClick={() => handleCompliance(allow === 'approved' ? 'approved' : 'denied')}>
              {allow === 'approved' ? 'Confirm Approve' : 'Confirm Deny'}
              </Button>
            </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default ComplianceModal;