import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { traderRequests } from '../api/transactions'
import { setFundPageCount } from '../redux/actions/action'
import CategoryFilter from './Filters/CategoryFilter'
import SortByFilter from './Filters/SortByFilter'
import { getToken } from './funtions/getToken'
import RequestsTableComponent from './RequestsTableComponent'

const TradersRequests = () => {
    const token = getToken()
    const [tableData, setTableData] = useState();
    const dispatch = useDispatch()
    let start = useSelector((state) => state.fundsPaginationReducer.start)
    let filters = useSelector((state) => state.filtersReducer)

    const getTraderRequestData = async () => {
        const payload = {
            start,
            to: filters.from,
            from: filters.to,
            status: filters.category
        }
        Object.keys(payload).forEach(key => {
            if (payload[key] === null) {
                delete payload[key];
            }
        });

        const result = await traderRequests(payload, token);
        if (result.status === 200) {
            if (result.data.is_success) {
                setTableData(result?.data?.data?.traderRequests)
                dispatch(setFundPageCount(result?.data?.data?.total_pages))
            }
        }
    }
    useEffect(() => {
        getTraderRequestData();
        // eslint-disable-next-line
    }, [start, filters])

    return (
        <>
            <Box display="inline-flex" width="100%" justifyContent="flex-end" alignItems="center" >
                <Box marginRight="10px">
                    <CategoryFilter trader />
                </Box>
                <Box >
                    <SortByFilter trader />
                </Box>
            </Box>
            <RequestsTableComponent hasUser isTrader={true} data={tableData} request="trader" />

        </>
    )
}

export default TradersRequests