import { makeStyles, Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Hidden, OutlinedInput, List, ListItem, Switch, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, FormControlLabel, CircularProgress, Snackbar, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DashboardLayout from './layout/DashboardLayout';
import profilePic from "../assets/images/profile.svg"
import { changeUserStatus, singleUserData,  } from '../api/user';
import Cookies from 'universal-cookie/es6';
import { useParams } from 'react-router-dom';
import { SaveAlt, PictureAsPdfOutlined,HighlightOff,CheckCircleOutline } from "@material-ui/icons";
import ColoredSpan from '../components/ColoredSpan';
import EditProfileModal from '../components/EditProfileModal';
import BanProfileModal from '../components/BanProfileModal';
import ErrorModal from '../components/ErrorModal';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        borderRadius: 10,
    }
});

const UserDetails = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [errorOpen, setErrorOpen] = useState(null);
    const [openBan, setBanOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        checkedB: true,
    });
    const [snackState, setSnackState] = useState({
        open: false,
        msg: null
    })
    const [usersData, setUsersData] = useState({});
    const cookies = new Cookies();
    const token = cookies.get("token");
    const params = useParams()
    //eslint-disable-next-line
    const [transactionInput, setTransactionInput] = useState({
        id: params.id,
        strategies: "",
        stock: "",
    });
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        getUserData()
        setErrorOpen(false)
        setOpen(false);
        setBanOpen(false);
    };
    const handleBanOpen = () => {
        setBanOpen(true);
    };

    const downloadAllClick = async () => {
        const zip = new JSZip();

        
        for (const doc of usersData.userTrader.compliance.documents) {
            try {
                const response = await fetch(doc.url);
                const blob = await response.blob();

                // Extract filename from URL
                const filename = doc.file
                // Add the file to the zip archive
                zip.file(filename, blob);
            } catch (error) {
                console.error(`Error downloading file from ${doc.url}:`, error);
            }
        }

        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: 'blob' });
  
        // Creating the zip file
        saveAs(zipBlob, `compliance_${usersData.user_name}.zip`);
    }
    const downloadClick = (file) => {
            const url = file.url; // Replace with your actual file URL
            fetch(url)
              .then(response => response.blob())
              .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = file.file; // Specify the filename for the downloaded file
        
                // Append the link to the document and trigger a click
                document.body.appendChild(link);
                link.click();
        
                // Remove the link and revoke the URL after the download is initiated
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);
              })
              .catch(error => {
                console.error('Error downloading file:', error);
              });
    }

    const formatDateTime = (dateTimeString) => {
        const dateObject = new Date(dateTimeString);

        // Format date using toLocaleString and DateTimeFormat options
        const formattedDateString = dateObject.toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Singapore',
        });
    
        // Format time and UTC information
        const formattedTimeString = dateObject.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'Asia/Singapore',
        });
    
        // Combine date and time with UTC information
        const finalFormattedDateTime = (
            <span>
              {formattedDateString} {formattedTimeString} (UTC +8 MY)
            </span>
          );    
        return finalFormattedDateTime;
      };
    const getUserData = async () => {
        const result = await singleUserData(params.id, token);
        if (result.status === 200) {
            if (result.data.is_success) {
                let userData = result.data.data;
                setState((prev) => {
                    return {
                        ...prev,
                        checkedB: userData.status
                    }
                })

                setUsersData(userData)
            }
        }
        setLoading(false);
    };
    const handleBan = async (status) => {
        try {
        const result = await changeUserStatus(params.id, status, token);
        if (result.status === 200) {
            if (result.data.is_success) {
            handleClose()
            getUserData();
            }
        }
    } catch (error) {
        setLoading(false);
    }
    };
    const checkError = () => {
        if(error != null){
            setErrorOpen(true)
        }
        if(error === null){
            handleClose()
        }
    }
    useEffect(() => {
        getUserData()
        //eslint-disable-next-line
    }, [state.checkedB])

    useEffect(() => {
        checkError()
    }, [error])
    return <DashboardLayout>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackState.open}
            onClose={handleClose}
            message={snackState.msg}
        />
        {loading ? <Box display={"flex"} justifyContent="center" alignItems={"center"} width={"100%"} height={"80vh"}> <CircularProgress color='secondary' /></Box> : <Grid className='mx-1' container spacing={2} >
            <Grid item xs={12}>
                    <Box display={"flex"} flexGrow={1} >
                        <Box display={"flex"} flexDirection="column" width={"100%"}>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-14 fw-500 text-grey'>Pages / Users / Profile</Box>
                            </Box>
                            <Box display={"inline-flex"} alignItems="flex-end" marginY={1}>
                                <Box className='fs-24 fw-700 text-blue-dark'>Profile</Box>
                            </Box>
                        </Box>
                    </Box>
            </Grid>

            <Grid item xs={12} md={12}   >
                <Paper className='px-1 border-radius-10' >
                    <Box className='h-400 overflow-hidden' width={"100%"} display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}  paddingX="20px">
                        <Box className='fs-24 fw-700 text-blue-dark'>Profile Information</Box>
                        <Box borderRadius={"8px"} paddingY="15px" display="inline-flex" justifyContent={"space-between"} width="100%" >
                        <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"}  paddingY={3}>
                            <Box>< img width="100px" height="100px" src={usersData?.profile.profile_image !== "null" ? usersData.profile.profile_image : profilePic} alt={usersData?.name}/></Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"} paddingX={3} >
                                <Box marginTop="12px" className='fw-700 fs-24 text-blue-dark'>{usersData.username}</Box>
                                <Box marginTop="12px" className='fw-500 fs-15 text-blue-dark'>{usersData._id}</Box>
                            </Box>
                            </Box>
                            <Box display={"inline-flex"} flexDirection="row" alignItems={"center"} justifyContent={"center"} >
                            <Box display={"inline-flex"} alignItems="center" >
                                                <Box marginRight={-4}> {usersData.status === 'active' ? <ColoredSpan color={"success"} background={"transparent"} fontSize={16}><CheckCircleOutline  fontSize='medium'/></ColoredSpan > : usersData.status === 'suspended' ? <ColoredSpan color={"error"} background={"transparent"} fontSize={16}><HighlightOff  fontSize='medium'/></ColoredSpan> : ''}</Box>
                                                <Box> {usersData.status === 'active' ? <ColoredSpan color={"success"} background={"transparent"} fontSize={16}>Active</ColoredSpan > : usersData.status === 'suspended' ? <ColoredSpan color={"error"} background={"transparent"} fontSize={16}>Suspended</ColoredSpan> : ''}</Box>
                                            </Box >
                            </Box>
                        </Box>
                        <Box  width={"100%"}>
                        <Divider fullWidth/>
                        </Box>
                        <Box borderRadius={"8px"} paddingY="15px" display="inline-flex" justifyContent={"space-between"} width="100%" >
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <span className='fs-16 fw-500 text-black'>Email</span>
                                <span className='fs-16 fw-700 text-blue'>{usersData.email}</span>
                            </Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <span className='fs-16 fw-500 text-black'>Phone Number</span>
                                <span className='fs-16 fw-700 text-blue'>{usersData.profile.phone_number ? usersData.profile.phone_number : '-'}</span>
                            </Box>
                            <Box display={"flex"} flexDirection="column" alignItems={"start"} justifyContent={"center"}>
                                <span className='fs-16 fw-500 text-black'>Date of Birth</span>
                                <span className='fs-16 fw-700 text-blue'>{usersData.profile.dob ? usersData.profile.dob : 'N/A'}</span>
                            </Box>
                                <Box className='my-2' width={"20%"}><Button className=' h-40' fullWidth color="secondary" variant="contained" onClick={handleOpen} >Edit profile</Button></Box>
                            </Box>
                    </Box>
                </Paper>
                <Box display={"flex"} flexDirection="row" alignItems={"center"} justifyContent={"end"}>
                                <Box paddingRight={2}><span className='fs-12 fw-400 text-grey'>You have the right to suspend the user if they did not comply to the regulations.</span></Box>
                                <Box className='my-2' width={"20%"}><Button className=' h-40' fullWidth color="secondary" variant="contained" onClick={handleBanOpen} >{usersData.status ==='active' ? 'Suspend User' : 'Unsuspend User'}</Button></Box>
                            </Box>
                            

            </Grid>
            <EditProfileModal open={open} handleClose={handleClose} id={params.id} token={token} email={usersData.email} username={usersData.username} setError={setError}/>
            <ErrorModal handleClose={handleClose} error={error} open={errorOpen} setError={setError}/>
            <BanProfileModal open={openBan} handleClose={handleClose} status= {usersData.status} handleBan={handleBan}/>
        </Grid>
        }
    </DashboardLayout >;
};

export default UserDetails;
