const initialState = {
    toggle: false
  };
  
  const sidebarSwitch = (state = initialState, action) => {
    if (action.type === "toggleSidebar") {
      state = {
        ...state,
        toggle: action.payload,
      };
      return state;
    } else {
      return state;
    }
  };
  
  export default sidebarSwitch;