import Api from "./index";

export const AllUsers = async (start, name, sort, token) => {
  try {
    return await Api.get(
      `/api/v1/admin/list/profiles?start=${start}&mode=paper&name=${name}&sort=${sort}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const singleUserData = async (id, token) => {
  try {
      return await Api.get(`/v1/admin/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const getPlanById = async (id, token) => {
  try {
    return await Api.get(`/api/v1/admin/single-plan/${id}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const UserTransactions = async (
  { id, stock, strategies },
  from,
  to,
  start,
  token
) => {
  try {
    return await Api.get(
      `/api/v1/admin/profile/${id}/order/transaction?from=${from}&to=${to}&start=${start}&stock=${stock}&strategy_id=${strategies}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const userBrokers = async (id, token) => {
  try {
    return await Api.get(`/api/v1/admin/profile/${id}/broker/all`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const userPayments = async (id, start, token) => {
  try {
    return await Api.get(`/api/v1/admin/profile/${id}/billing?start=${start}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const logout = async (token) => {
  try {
    return await Api.post(
      `/api/v1/admin/logout`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const adminProfile = async (token) => {
  try {
    return await Api.get(`/api/v1/admin/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const changeUserStatus = async (id, status, token) => {
  try {
    return await Api.patch(`/v1/admin/user/${id}`, 
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,        
        },
      }
    );
  } catch (error) {
    return {
      error
    };
  }
};

export const editUsernameEmail = async (id, username, email, token ) => {
  try {
    return await Api.patch(`/v1/admin/user/${id}`, 
    {
        username : username,
        email: email
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const getComplianceList = async (start, filter, sort, status, token ) => {
  try {
    return await Api.get(`/api/v2/admin/compliance/${start}?search=${filter}&status=${status}&sort=${sort}`,
    {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const singleComplianceData = async (id, token) => {
  try {
    return await Api.get(`/api/v2/admin/compliance?traderId=${id}`, {
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const handleAllowCompliance = async (id, status, reason, token) => {
  try {
    return await Api.post(`/api/v2/admin/compliance/${id}/update`, 
    {  
        status,
        reason,
        justified_category: []
    },{
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const userExcel = async (filter, sort, status, token ) => {
  try {
    return await Api.get(`/v1/admin/download/users?filter=${filter}&status=${status}&sort=${sort}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const invitedUserData = async (id, startDate, endDate, token) => {
  try {
    return await Api.get(
      `/api/v1/admin/profile/${id}/invitation-status${startDate ? `?start_date=${startDate}` : ""
      }${startDate && endDate ? `&end_date=${endDate}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch {
    return {
      status: 404,
    };
  }
};

export const adminModeSwitch = async (mode, token) => {
  try {
    return await Api.patch(
      `/api/v1/users/mode/switch`,
      {
        mode,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (error) {
    return {
      status: 404,
    };
  }
};

export const usersListing = async (start, filter, sort, status, token) => {
  try {
    return await Api.get(`/v1/admin/users/${start}?filter=${filter}&status=${status}&sort=${sort}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
export const userPortfolio = async (
  start,
  user_id,
  token
) => {
  try {
    return await Api.get(`/api/v1/admin/portfolio/stocks`, {
      params: {

        start,
        user_id,
      },
      headers: {
        Authorization: token,
      },
    });
  } catch (error) {
    return {
      status: 404,
    };
  }
};
// /admin/portfolio/stocks
